import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { themeColor } from '../../util/themeLenses'
import Copy from '../Copy'
import Flex from '../Flex'

const DropdownMenuDesktopContent = styled(Flex)`
    display: none;
    right: 0;
    top: 60px;
    border: 1px solid ${themeColor('gray20')};
    border-top: 0;
    box-shadow: 2px 4px 5px 0px rgba(0, 0 ,0, 0.2);
    border-top: 1px solid ${themeColor('gray20')};
    background: ${themeColor('white')};
`

const DropdownMenuDesktopMain = styled(Flex)`
    cursor: pointer;
    white-space: nowrap;
    &:hover > ${DropdownMenuDesktopContent} {
        z-index: 215;
        display: flex;
    }
`

const DropdownMenuDesktopContentItem = styled(Copy)`
    border-bottom: 1px solid ${themeColor('gray60')};
    height: 60px;

    &:hover {
        color: ${themeColor('main')};
        border-bottom-color: ${themeColor('main')};
    }

    &:last-child {
        border-bottom: 0;
    }
`

const DropdownMenuDesktop = ({ desktopMenu }) => (

    <DropdownMenuDesktopMain display='flex' alignItems='center' position='relative' justifyContent='flex-end' height='60px' lineHeight='60px'>
        { <>
                {desktopMenu.renderButtonValue}
                <DropdownMenuDesktopContent width='270px' position='absolute' padding='0px 10px' flexDirection='column'>
                    {desktopMenu.subItems && desktopMenu.subItems.map((subItem, key) =>
                        <DropdownMenuDesktopContentItem as='a' color='gray60' textDecoration='none' padding='0 8px' fontSize='large' fontWeight='book' align='left' lineHeight='60px' key={`desktop_menu_${key}`} href={subItem.url}>{subItem.text}</DropdownMenuDesktopContentItem>
                    )}
                </DropdownMenuDesktopContent>
            </>
        }
    </DropdownMenuDesktopMain >
)

DropdownMenuDesktop.propTypes = {
    desktopMenu: PropTypes.shape({
        renderButtonValue: PropTypes.node,
        subItems: PropTypes.arrayOf(PropTypes.object),
    })
}

export default DropdownMenuDesktop
