import React from 'react'
import PropTypes from 'prop-types'
import {
  ID_WORKSHOP_PRODUCT_OFFICIAL_CHECK_UP,
  ID_WORKSHOP_PRODUCT_BATTERY,
  ID_WORKSHOP_PRODUCT_WIPER_BLADE,
  ID_WORKSHOP_PRODUCT_SANITATION,
  ID_WORKSHOP_PRODUCT_AIR_CONDITIONING,
  ID_WORKSHOP_PRODUCT_BATTERY_START_STOP,
  ID_WORKSHOP_PRODUCT_PRE_INSPECTION_CHECK_UP,
} from './constants'

import airConditioning from './productIcons/airConditioning.svg'
import battery from './productIcons/battery.svg'
import batteryStartStop from './productIcons/batteryStartStop.svg'
import officialCheckUp from './productIcons/officialCheckUp.svg'
import oilChange from './productIcons/oilChange.svg'
import preInspectionCheckUp from './productIcons/preInspectionCheckUp.svg'
import sanitation from './productIcons/sanitation.svg'
import wiperBlade from './productIcons/wiperBlade.svg'

const mapIcons = {
  airConditioning: airConditioning,
  batteryStartStop: batteryStartStop,
  battery: battery,
  officialCheckUp: officialCheckUp,
  oilChange: oilChange,
  preInspectionCheckUp: preInspectionCheckUp,
  sanitation: sanitation,
  wiperBlade: wiperBlade,
}

WorkshopProductIcon.propTypes = {
  type: PropTypes.oneOf(
    Object.values({
      ID_WORKSHOP_PRODUCT_OFFICIAL_CHECK_UP,
      ID_WORKSHOP_PRODUCT_BATTERY,
      ID_WORKSHOP_PRODUCT_WIPER_BLADE,
      ID_WORKSHOP_PRODUCT_SANITATION,
      ID_WORKSHOP_PRODUCT_AIR_CONDITIONING,
      ID_WORKSHOP_PRODUCT_BATTERY_START_STOP,
      ID_WORKSHOP_PRODUCT_PRE_INSPECTION_CHECK_UP,
    })
  ),
  height: PropTypes.string,
}

WorkshopProductIcon.defatultProps = {
  height: 'auto',
}

function WorkshopProductIcon({ type, height }) {
  const workshopProductsIconMap = {
    [ID_WORKSHOP_PRODUCT_OFFICIAL_CHECK_UP]: 'officialCheckUp',
    [ID_WORKSHOP_PRODUCT_BATTERY]: 'battery',
    [ID_WORKSHOP_PRODUCT_WIPER_BLADE]: 'wiperBlade',
    [ID_WORKSHOP_PRODUCT_SANITATION]: 'sanitation',
    [ID_WORKSHOP_PRODUCT_AIR_CONDITIONING]: 'airConditioning',
    [ID_WORKSHOP_PRODUCT_BATTERY_START_STOP]: 'batteryStartStop',
    [ID_WORKSHOP_PRODUCT_PRE_INSPECTION_CHECK_UP]: 'preInspectionCheckUp',
  }

  const icon = workshopProductsIconMap[type]

  if (!icon) {
    return null
  }

  return <img src={mapIcons[icon]} height={height} />
}

export default WorkshopProductIcon
