/* Date and time constants */

export const TIMESTAMP_FORMAT = 'X'
export const HOUR_FORMAT = 'HH:mm'
export const HOUR_FORMAT_WITH_SECONDS = 'HH:mm:ss'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm'
export const DATETIME_FORMAT_WITH_SECONDS = 'YYYY-MM-DDTHH:mm:ss'
export const DATETIME_FORMAT_WITH_SECONDS_WITH_SPACE = 'YYYY-MM-DD HH:mm:ss'
export const DATETIME_FORMAT_WITH_MILLISECONDS = 'YYYY-MM-DDTHH:mm:ss.SSS'
export const LOCALE_DATE_FORMAT = 'L'
export const LOCALE_DATETIME_FORMAT = 'L LT'
export const LOCALE_TIME_FORMAT = 'LT'
export const LOCALE_DATETIME_HUMAN_LONG_FORMAT = 'LLLL'
export const LOCALE_DATETIME_HUMAN_LONG_WITHOUTYEAR_FORMAT = 'dddd, D MMMM, LT'
export const LOCALE_DATETIME_HUMAN_SHORT_FORMAT = 'lll'
export const LOCALE_DATETIME_HUMAN_SHORT_WITHOUTYEAR_FORMAT = 'ddd, DD MMM, LT'
export const LOCALE_DATE_HUMAN_SHORT_WITHOUTYEAR_FORMAT = 'dddd, DD MMM'
export const CALENDAR_DATETIME_FORMAT = 'DD MMM, LT'
export const YEAR_FORMAT = 'YYYY'
export const YEAR_MONTH_FORMAT = 'MMM YYYY'
export const MONTH_SHORT_FORMAT = 'MMM'
export const MONTH_LONG_FORMAT = 'MMMM'
export const MONTH_FORMAT = 'M'
export const YEAR_MONTH_LONG_FORMAT = 'MMMM YYYY'
export const DAY_OF_MONTH_NUMERIC_FORMAT = 'D'
export const DAY_HUMAN_LONG_FORMAT = 'dddd'
export const MERIDIEM_FORMAT = 'a'
export const HOUR_24_FORMAT = 'HH'
export const HOUR_12_FORMAT = 'hh'
export const MINUTES_FORMAT = 'mm'
export const EXPIRATION_DATE_FORMAT = 'MM/YY'
export const TIMEZONE_FORMAT = 'z'

export const HOURS_STEP = 1
export const MINUTES_STEP = 5

export const HOUR = 'hour'
export const DAY = 'day'
