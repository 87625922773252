import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { height, background, fontSize, fontWeight, width, space } from '../../util/styles'
import Copy from '../Copy'
import { themeColor } from '../../util/themeLenses'

const DefaultInputComponent = styled.input`
    -webkit-appearance: none;
    -moz-appearance: textfield;
    text-align: center;
    color: ${themeColor('gray')};
    background: ${themeColor('lightGray20')};
    border: 1px solid ${themeColor('main20')};
    outline: none;
    padding: 0;
    ${fontSize}
    ${fontWeight}
    ${background}
    ${height}
    ${width}
    &:focus {
        border-color: ${themeColor('main')};
        background: ${themeColor('main20')};
        color: ${themeColor('main')};
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`

const InputContainer = styled.div`
    text-align: center;
    display: inline-block;
    ${space}
    &:last-child {
        margin-right: 0;
    }
`

const getString = (event, type) => {
    var regex = new RegExp(type === 'number' ? '^[0-9]+$' : '^[a-zA-Z0-9]+$')
    var str = event.key && event.key.length === 1 && event.key
    if (regex.test(str)) {
        return str
    }
    return null
}

const isDeleteKey = (event) => {
    const key = event.which || event.keyCode || event.charCode
    return key === 8
}

const isEnterKey = (event) => {
    return event.key === 'Enter'
}

const CodeInput = ({
    size,
    type,
    onChange,
    groupBy,
    separator,
    spaceBetweenInput,
    spaceBetweenGroup,
    fontSize,
    width,
    dataTest,
    height,
    inputComponent: InputComponent,
    ...restProps
}) => {
    const [ values, setValues ] = useState(Array(size).fill(''))

    useEffect(() => {
        onChange(values.join(''))
    }, [values, onChange])

    const onKeyHandler = (index) => (event) => {
        if (isEnterKey(event)) {
            return
        }
        const target = event.target.parentElement
        const value = getString(event, type)
        const newValues = [...values]
        const prevValue = values[index]
        const next = target.nextSibling
        const prev = target.previousSibling
        const onDelete = isDeleteKey(event)
        newValues[index] = value || ''
        if (value && next) {
            next.firstChild.focus()
        }
        if (onDelete && prev) {
            if (!prevValue) {
                prev.firstChild.focus()
                newValues[index - 1] = ''
            }
        }
        setValues(newValues)
    }

    return <fieldset onPaste={(event) => {
            event.stopPropagation()
            event.preventDefault()
            const text = event.clipboardData.getData('Text').replace(/\s+/g, '').substr(0, size).split('')
            const newValues = Array(size).fill('')
            text.forEach((val, index) => { newValues[index] = val })
            setValues(newValues)
            event.target.parentElement.parentElement.childNodes[text.length - 1].firstChild.focus()
        }}>{ values.map((value, index) => {
            const showGroup = (index !== (values.length - 1)) &&
                (((index + 1) % groupBy) === 0)
            return <InputContainer key={`code_input_${index}`} marginRight={showGroup ? spaceBetweenGroup : spaceBetweenInput}>
                <InputComponent
                  dataTest={`${dataTest}[${index}]`}
                  tabIndex={index + 1}
                  value={value}
                  onChange={() => {}}
                  type={type}
                  maxLength={1}
                  size={1}
                  width={width}
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                  height={height}
                  onKeyDown={onKeyHandler(index)}
                  {...restProps} />
                { showGroup && separator &&
                    <Copy display='inline-block' as='span' marginLeft={spaceBetweenInput} marginRight={spaceBetweenInput} padding='0' textAlign='center' fontSize={fontSize} weight={fontWeight} >
                        { separator }
                    </Copy>
                }
            </InputContainer>
        }) }</fieldset>
}

CodeInput.propTypes = {
    onChange: PropTypes.func,
    size: PropTypes.number,
    dataTest: PropTypes.string,
    groupBy: PropTypes.number,
    separator: PropTypes.string,
    inputComponent: PropTypes.any,
    spaceBetweenInput: PropTypes.any,
    spaceBetweenGroup: PropTypes.any,
    fontSize: PropTypes.any,
    width: PropTypes.any,
    height: PropTypes.any,
    type: PropTypes.oneOf(['password', 'text', 'number']),
}

CodeInput.defaultProps = {
    onChange: () => {},
    groupBy: 3,
    size: 6,
    dataTest: 'code',
    fontSize: 'large',
    fontWeight: 'large',
    type: 'password',
    inputComponent: DefaultInputComponent,
    separator: '',
    height: '3em',
    width: ['40px', '2em'],
    spaceBetweenInput: '0.5em',
    spaceBetweenGroup: '2em',
}

export default CodeInput
