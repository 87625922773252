/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'

const RenderSwitch = React.forwardRef(({ test, defaultComponent, ...restProps}, ref) => {
    // eslint-disable-next-line jest/no-disabled-tests
    const renderProps = Object(test())
    // NOTE: We are relying on render props order. First declared wins
    const foundProp = Object.keys(renderProps).find((propName) => renderProps[propName] && restProps[propName])
    const renderComponent = restProps[foundProp] || defaultComponent
    // TODO: Omit known RenderSwitch props (inferred from test call result)
    return React.createElement(renderComponent, {...restProps, ref})
})

RenderSwitch.propTypes = {
    test: PropTypes.func.isRequired,
    defaultComponent: PropTypes.elementType,
}

RenderSwitch.defaultProps = {
    defaultComponent: () => null,
}

export default RenderSwitch
