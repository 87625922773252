const promoBannerDefaultValues = {
    active: false,
    text: '',
    link_text: '',
    link_url: '',
    enable_on_mobile: false,
    campaign: '',

}

const defaultConfig = {
    'banner_invoice_list_bottom': JSON.stringify({
        active: false,
        image_url: '',
        link_url: '',
    }),
    'promo_banner_home': JSON.stringify(promoBannerDefaultValues),
    'promo_banner_parking_search': JSON.stringify(promoBannerDefaultValues),
    'promo_banner_parkings': JSON.stringify(promoBannerDefaultValues),
    'promo_banner_itv': JSON.stringify(promoBannerDefaultValues),
    'promo_banner_airport': JSON.stringify(promoBannerDefaultValues),
    'promo_banner_airport_parkings': JSON.stringify(promoBannerDefaultValues),
    'promo_banner_charging_point_search': JSON.stringify(promoBannerDefaultValues),
    'promo_banner_workshop': JSON.stringify(promoBannerDefaultValues),
}

export default defaultConfig
