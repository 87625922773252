import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Flex from '../Flex'
import Box from '../Box'
import Copy from '../Copy'
import HorizontalRule from '../HorizontalRule'
import { DotsLoader } from '../Loader'


import { DateConstants, formatDate } from '@elparking/utils'
import ScrollAnchor from './ScrollAnchor'
import Hours from './Hours'
import Days from './Days'

const LOCALE_DATE_HUMAN_SHORT_WITHOUTYEAR_FORMAT = DateConstants && DateConstants.LOCALE_DATE_HUMAN_SHORT_WITHOUTYEAR_FORMAT


const getDateKey = (date) => `${date.getMonth()}${date.getDate()}`
const getModifiers = (days) => ({
    inactive: {
        before: days[0],
        after: days[days.length - 1],
    },
})

const ExpandedCalendar = ({ days, hours, value, onChangeDay, onChangeHour, isLoading, leftArrowImage, rightArrowImage }) => {
    const [selectedDay, setSelectedDay] = useState(value)
    const [selectedHour, setSelectedHour] = useState(value)
    const scrollAnchor = useRef(null)
    const selectableDays = days.map((date) => getDateKey(date))

    function handleChangeDay (date) {
        setSelectedHour(null)
        setSelectedDay(date)
        onChangeDay(date)
    }

    function handleChangeHour (date) {
        setSelectedHour(date)
        onChangeHour(date)
    }

    useEffect(() => {
        if (!hours) {
            return
        }

        if (scrollAnchor.current) {
            scrollAnchor.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            })
        }
    }, [hours])

    return (
        <Box>
            <Days
              actives={days}
              selectedDay={selectedDay}
              disabledDays={(date) => !selectableDays.includes(getDateKey(date))}
              month={selectedDay}
              fromMonth={days[0]}
              toMonth={days[days.length - 1]}
              modifiers={getModifiers(days)}
              onChange={handleChangeDay}
              leftArrowImage={leftArrowImage}
              rightArrowImage={rightArrowImage}
            />
            {selectedDay && (
            <>
                <HorizontalRule />
                <ScrollAnchor ref={scrollAnchor} />
                <Box mb='1em'>
                    <Copy fontSize='xLarge' fontColor='gray80' fontWeight='medium'>
                        <FormattedMessage
                          defaultMessage='Seleccionar hora para el {date}'
                          values={{ date: formatDate(selectedDay, LOCALE_DATE_HUMAN_SHORT_WITHOUTYEAR_FORMAT) }}
                        />
                    </Copy>
                </Box>
                <Flex w='100%' justifyContent='center' alignItems='center' >
                    {isLoading
                    ? (<DotsLoader />)
                    : (<Hours
                      hours={hours}
                      selectedHour={selectedHour}
                      onChange={handleChangeHour}
                      />)
                }
                </Flex>
            </>)
            }
        </Box>
    )
}

ExpandedCalendar.propTypes = {
    days: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
    hours: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
    onChangeDay: PropTypes.func,
    onChangeHour: PropTypes.func,
    value: PropTypes.instanceOf(Date),
    isLoading: PropTypes.bool,
    leftArrowImage: PropTypes.string,
    rightArrowImage: PropTypes.string,
}

ExpandedCalendar.defaultProps = {
    onChangeDay: () => {},
    onChangeHour: () => {},
    days: [],
}

export default ExpandedCalendar
