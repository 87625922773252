import React from 'react'
import PropTypes from 'prop-types'
const ConfigContext = React.createContext()
const SPAIN = { iso2: 'es', iso3: 'esp', name: 'España', defaultLanguage: { code: 'es' } }

function ConfigProvider ({ children, ...config }) {
    return (
        <ConfigContext.Provider value={{...config}}>
            {children}
        </ConfigContext.Provider>
    )
}

ConfigProvider.defaultProps = {
    country: 'esp',
    currencyFormats: [
        {
            symbol: '€',
            code: 'EUR',
            fractionDigits: 2,
            format:'{sign}{number} {currency}'
        },
        {
            code: 'USD',
            symbol: '$',
            fractionDigits: 2,
            format:'{sign}{currency}{number}'
        },
        {
            code: 'CRC',
            symbol: '\u20a1',
            fractionDigits: 0,
            format:'{sign}{number} {currency}'
        }
    ],
    defaultCountry: SPAIN,
    countries: [ SPAIN ],
    excludedFlavors: [ 'maggie' ],
    tradename: 'ElParking',
    facebookNetworkName: 'facebook-el-parking',
    googleNetworkName: 'google-el-parking',
    parkingdoorUrl: 'https://parkingdoor.com',
    blogUrl: 'https://elparking.com/blog/',
}

ConfigProvider.propTypes = {
    blogUrl: PropTypes.string,
    parkingdoorUrl: PropTypes.string,
    country: PropTypes.string.isRequired,
    tradename: PropTypes.string.isRequired,
    facebookAppId: PropTypes.string.isRequired,
    googleAppId: PropTypes.string.isRequired,
    facebookNetworkName: PropTypes.string.isRequired,
    googleNetworkName: PropTypes.string.isRequired,
    googleRecapchaSitekey: PropTypes.string.isRequired,
    defaultCountry: PropTypes.shape({
        iso3: PropTypes.string,
        iso2: PropTypes.string,
        name: PropTypes.string,
    }),
    countries: PropTypes.arrayOf(PropTypes.shape({
        iso3: PropTypes.string,
        iso2: PropTypes.string,
        name: PropTypes.string,
    })),
    currencyFormats: PropTypes.arrayOf(
        PropTypes.shape({
            symbol: PropTypes.string,
            fractionDigits: PropTypes.number,
            format: PropTypes.string,
            tradename: '',
        })
    ).isRequired,
    children: PropTypes.node,
}

export {
    ConfigProvider,
    ConfigContext,
}