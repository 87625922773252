import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { themeColor, themeFontSize } from '../../util/themeLenses'
import {
    color,
    fontSize,
    fontWeight,
} from '../../util/styles'
import Icon from '../Icon'

const containerBorderColor = ({ valid, changed, errorColor }) => !valid && changed ? themeColor(errorColor) : '0'
const containerBorderWidth = ({ valid, changed }) => !valid && changed ? `2px` : '0'
const containerPadding = ({ valid, changed }) => !valid && changed ? '8px' : '0'
const DEFAULT_BOX_SIZE = '16px'

export const CheckboxInputContainer = styled.div`
    border-color: ${containerBorderColor};
    border-style: solid;
    border-width: ${containerBorderWidth};
    padding: ${containerPadding};
    border-radius: 2px;
`

CheckboxInputContainer.propTypes = {
    errorColor: PropTypes.string,
    valid: PropTypes.bool,
    changed: PropTypes.bool,
}

CheckboxInputContainer.defaultProps = {
    errorColor: 'red',
}

export const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    ${fontSize};
    ${color};
    ${fontWeight};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

CheckboxLabel.propTypes = {
    fontColor: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
}

const CheckButton = styled.div`
    flex: 0 0 auto;
    display: inline-block;
    width: ${DEFAULT_BOX_SIZE};
    height: ${DEFAULT_BOX_SIZE};
    border-radius: 50%;
    border: 1px solid ${themeColor('gray60')};
    background: ${themeColor('white')};
    box-sizing: border-box;
    margin-right: 8px;
    position: relative;

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        left: calc(${DEFAULT_BOX_SIZE} / 2);
        top: calc(${DEFAULT_BOX_SIZE} / 2);
        width: 0;
        height: 0;
        border-radius: 50%;
        box-sizing: border-box;
        margin-right: 8px;
        background: ${themeColor('main')};
        transition: 0.1s;
    }

    input:checked ~ label > &:after {
        left: 2px;
        top: 2px;
        width: calc(${DEFAULT_BOX_SIZE} - 6px);
        height: calc(${DEFAULT_BOX_SIZE} - 6px);
    }
`

CheckButton.squared = styled(CheckButton)`
    border-radius: 0;
    &:after {
        border-radius: 0;
    }
`

CheckButton.toggle = styled(CheckButton)`
    width: 40px;
    height: 24px;
    background: red;
    border-radius: 50px;
    position: relative;
    background: ${themeColor('white')};
    border: 1px solid ${themeColor('gray20')};

    &:after {
        border-radius: 50%;
        border: 1px solid ${themeColor('gray20')};
        top: -1px;
        left: 0px;
        height: 24px;
        width: 24px;
        content: ' ';
        display: block;
        background: ${themeColor('white')};
        position: absolute;
        transition: left ease-out 0.3s;
        box-shadow: 0 1px 3px rgba(0,0,0,.3);
    }

    input:checked ~ label > &:after {
        border: 1px solid ${themeColor('gray20')};
        top: -1px;
        left: 16px;
        height: 24px;
        width: 24px;
    }

    input:checked ~ label > & {
        background: ${themeColor('main')};
    }

`

const CheckButtonIconFilledContainer = styled.div`
    display: flex;
    width: calc(${(props) => themeFontSize(props.fontSize)} + 8px);
    height: calc(${(props) => themeFontSize(props.fontSize)} + 8px);
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    align-items: center;
    justify-content: center;
    border-color: ${(props) => (props.checked ? themeColor('accent') : themeColor('gray60'))}; 
    background:  ${(props) => (props.checked ? themeColor('accent') : themeColor('white'))};
    margin-right: 8px;
`

const CheckButtonIconFilled = ({ iconType, checked, fontSize }) => {
    return (
        <CheckButtonIconFilledContainer checked={checked} fontSize={fontSize}>
            {checked && <Icon type={iconType} size={fontSize}/>
            }
        </CheckButtonIconFilledContainer>
    )
}

CheckButtonIconFilled.propTypes = {
    iconType: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    fontSize: PropTypes.string,
}

CheckButtonIconFilled.defaultProps = {
    iconType: 'ok',
    checked: false,
    disabled: false,
    fontSize: 'xxSmall'
}

CheckButton.iconFilled = CheckButtonIconFilled

export { CheckButton }

export const CheckboxInput = styled.input`
    display: none;
`
