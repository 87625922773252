import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { themeColor } from '../../util/themeLenses'
import Icon from '../Icon'
import Copy from '../Copy'
import Flex from '../Flex'
import Button from '../Button'
import Box from '../Box'

const DropdownMenuMobileMain = styled(Flex)`
    cursor: pointer;
    white-space: nowrap;
`
const DropdownMenuMobileSection = styled(Box)`
    border-bottom: 1px solid ${themeColor('gray20')};
    &:last-child {
        border-bottom: 0;
    }
`

const DropdownMenuMobileContent = styled(Flex)`
    z-index: 300;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: calc(100vh);
    overflow-y: scroll;
`

const DropdownMenuMobileContentItemLevel1 = styled(Flex)`
    border-top: 1px solid ${themeColor('gray20')};

    &:first-child {
        margin: 0;
        border-top: 0;
    }
`

const DropdownMenuMobileContentItemLevel3 = styled(Flex)`
    a:first-child {
        padding-top: 10px;
    }
    a:last-child {
        margin-bottom: 10px;
    }
`

const DropdownIcon = ({ onClick }) => (
    <Button.bordered dataTest='header-user-mobile-menu-button' padding='0px' borderColor='gray20' color='main' onClick={onClick}>
        <Copy size='xxLarge' fontWeight='book' color='main' padding='5px 10px'>
            <Icon type='menu' size='xxLarge' color='main' />
        </Copy>
    </Button.bordered>
)

DropdownIcon.propTypes = {
    onClick: PropTypes.func.isRequired
}

const DropdownMenuMobile = ({ mobileMenu, isMobileMenuOpen, logo }) => {
    const [isMobileMenuShown, setIsMobileMenuShown] = useState(isMobileMenuOpen)

    const checkActiveSubItem = (subItems) => {
        return subItems.some(subItem => subItem.active)
    }

    return (<>
        <DropdownMenuMobileMain position='relative' justifyContent='flex-end' flexDirection='column' alignItems='center'>
            <DropdownIcon onClick={() => setIsMobileMenuShown(!isMobileMenuShown)} />
        </DropdownMenuMobileMain>
        {isMobileMenuShown &&
            <DropdownMenuMobileContent dataTest='header-user-mobile-menu-content' backgroundColor='white' padding='0 10px 10px 10px' width='100%' flexDirection='column' lineHeight='medium' position='relative'>
                <Box textItems='right' textAlign='right' width='100%'>
                    <Flex justifyContent='space-between' alignItems='center' height='60px'>
                        {logo}
                        <DropdownIcon onClick={() => setIsMobileMenuShown(!isMobileMenuShown)} />
                    </Flex>
                </Box>
                {mobileMenu && mobileMenu.map((subSection, indexSection) =>
                    <DropdownMenuMobileSection key={`DropdownSection-${indexSection}`}>
                        <DropdownMenuMobileContentItemLevel1 backgroundColor='white' padding='20px 10px' color={checkActiveSubItem(subSection.subItems) || subSection.active ? 'gray' : 'gray60'}>
                            <Copy as='a' textDecoration='none' href={subSection.url} padding='0px' color='inherit' fontSize='large' fontWeight='book' >{subSection.title}
                                {subSection.subtitle &&
                                    <Copy padding='0px' marginTop='5px' fontSize='small' color='inherit' fontWeight='regular'>{subSection.subtitle}</Copy>
                                }
                            </Copy>
                        </DropdownMenuMobileContentItemLevel1>
                        <DropdownMenuMobileContentItemLevel3 flexDirection='column'>
                            {subSection.subItems && subSection.subItems.map((subItem, indexItem) =>
                                <Copy key={`DropdownItem-${indexSection}-${indexItem}`} as='a' textDecoration='none' href={subItem.url} padding='10px 20px' fontSize='medium' fontWeight='book' color={subItem.active ? 'main' : 'gray60'} >{subItem.text}</Copy>
                            )}
                        </DropdownMenuMobileContentItemLevel3>
                    </DropdownMenuMobileSection>
                )}
            </DropdownMenuMobileContent>
        }
    </>)
}

DropdownMenuMobile.defaults = {
    isMobileMenuOpen: false,
    mobileMenu: {},
}

DropdownMenuMobile.propTypes = {
    mobileMenu: PropTypes.array,
    isMobileMenuOpen: PropTypes.bool,
    logo: PropTypes.node,
}

export default DropdownMenuMobile
