/* istanbul ignore file */
import MobileDetect from 'mobile-detect'

function getMobileDetect () {
    if (typeof window === "undefined") {
        return new MobileDetect('')
    }
    return new MobileDetect(window.navigator.userAgent)
}

export function isMobile () {
    return getMobileDetect().mobile() !== null
}

export function isAndroid () {
    return getMobileDetect().os() === 'AndroidOS'
}

export function isIphone () {
    return getMobileDetect().os() === 'iOS'
}
