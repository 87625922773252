const logger = require('../logger')
const Events = require('./Events')

function pushEvent(event) {
  if (!event || !event.event) {
    logger.warn('[GTM] Event not defined')
    return
  }

  if (!window.dataLayer) {
    logger.warn(`[GTM] dataLayer not found! for ${event.event}`, event.payload)
    return
  }

  logger.info(`[GTM] Event triggered: ${event.event}`, event.payload)

  window.dataLayer.push(event)
}

export function trackEvent(event, payload = {}) {
  pushEvent({ event, payload })
}

export const initGoogleAnalytics = trackEvent.bind(
  this,
  Events.initGoogleAnalytics
)

export const trackSendContactForm = trackEvent.bind(
  this,
  Events.sendContactForm
)
