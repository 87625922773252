import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import RemoteConfigContext from './RemoteConfigContext'

export class Config {
  constructor(remoteConfig) {
    this.remoteConfig = remoteConfig
  }

  parseJSONValue(value) {
    try {
      return JSON.parse(value)
    } catch (error) {
      return null
    }
  }

  getJSON() {
    const values = this.remoteConfig.getAll()

    return Object.keys(values).reduce(
      (allValues, key) => ({
        ...allValues,
        [key]: this.parseJSONValue(values[key].asString()),
      }),
      {}
    )
  }

  fetch() {
    return this.remoteConfig.fetchAndActivate()
  }
}

function RemoteConfigProvider({ remoteConfig, children }) {
  const config = useMemo(() => new Config(remoteConfig), [remoteConfig])
  const [values, setValues] = useState(config.getJSON())

  useEffect(() => {
    config.fetch().then(() => setValues(config.getJSON()))
  }, [config])

  return (
    <RemoteConfigContext.Provider value={values}>
      {children}
    </RemoteConfigContext.Provider>
  )
}

RemoteConfigProvider.propTypes = {
  remoteConfig: PropTypes.object,
  children: PropTypes.node,
}

export default RemoteConfigProvider
