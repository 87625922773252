import React from 'react'
import styled from 'styled-components'
import { themeColor } from '../../util/themeLenses'
import PropTypes from 'prop-types'
import { COLORS } from '../../util/theme'
const iconColor = (props) => themeColor(props.iconColor)
const iconSize = (props) => props.iconSize

const IconLoader = styled.i`
    font-size: ${iconSize};
    color: ${iconColor};
`

const Block = styled.div`
    display: inline-block;
`

const Loader = ({ size, color }) =>
  <Block>
    <IconLoader className='icon-spin animate-spin'
      iconSize={size}
      iconColor={color}
    />
  </Block>

Loader.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}

Loader.defaultProps = {
  iconColor: 'gray',
  iconSize: '14px',
}

export default Loader

const DOTS_COLORS = COLORS.filter(color => !(/\d/.test(color)))
const backgrounColor = (percent = '') => ({ color }) => themeColor(color + percent)
const size = ({ dotSize }) => dotSize

export const DotsLoaderContainer = styled.div`
    display: inline-block;
    position: relative;
    width: calc(${size} * 6);
    height: calc(${size} * 2);
  }
  & div {
    position: absolute;
    top: calc(${size} / 2);
    width: ${size};
    height: ${size};
    border-radius: 50%;
    background: ${backgrounColor(40)};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  & div:nth-child(1) {
    left: calc(${size} / 2);
    animation: lds-ellipsis1 1s infinite;
  }
  & div:nth-child(2) {
    left: calc(2.5 * ${size});
    animation: lds-ellipsis2 1s infinite;
  }
  & div:nth-child(3) {
    left: calc(4.5 * ${size});
    animation: lds-ellipsis3 1s infinite;
  }

  @keyframes lds-ellipsis1 {
    33% {
      background: ${backgrounColor(20)};
    }
    66% {
      background: ${backgrounColor()};
    }
    100% {
      background: ${backgrounColor(40)};
    }
  }
  @keyframes lds-ellipsis2 {
    33% {
      background: ${backgrounColor(40)};
    }
    66% {
      background: ${backgrounColor(20)};
    }
    100% {
      background: ${backgrounColor()};
    }
    
  }
  @keyframes lds-ellipsis3 {
    33% {
      background: ${backgrounColor()};
    }
    66% {
      background: ${backgrounColor(40)};
    }
    100% {
      background: ${backgrounColor(20)};
    }
  }
`

DotsLoaderContainer.defaultProps = {
  color: PropTypes.string,
}

const DotsLoader = ({ color, dotSize }) => <DotsLoaderContainer dotSize={dotSize} color={color}><div /><div /><div /></DotsLoaderContainer>

DotsLoader.propTypes = {
  color: PropTypes.string,
  dotSize: PropTypes.string,
}

DotsLoader.defaultProps = {
  color: 'gray',
  dotSize: '12px',
}

export {
  DOTS_COLORS,
  DotsLoader,
}
