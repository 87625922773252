import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DateConstants, parseMoment, formatDay } from '@elparking/utils'
import { themeFontWeight, themeColor } from '../../util/themeLenses'
import Button from '../Button'
import Grid from '../Grid'
import Cell from '../Cell'

const LOCALE_TIME_FORMAT = DateConstants && DateConstants.LOCALE_TIME_FORMAT
const DATETIME_FORMAT = DateConstants && DateConstants.DATETIME_FORMAT


export const DateBox = styled(Button)`
    border: 1px solid ${themeColor('main')};
    border-radius: 1.2em;
    text-align: center;
    font-weight: ${themeFontWeight('regular')};
    &:hover {
        background: ${themeColor('main')};
        color: ${themeColor('white')};
    }
`

const Hour = ({ hour, onSelect, isSelected }) => (
    <DateBox
      onClick={() => onSelect(hour)}
      fontSize='medium'
      padding='0.6em 1em'
      width='5em'
      backgroundColor={isSelected ? 'main' : 'transparent'}
      fontColor={isSelected ? 'white' : 'gray'}
      data-test={`calendar-hour-${formatDay(hour, DATETIME_FORMAT)}`}
    >
        {formatDay(hour, LOCALE_TIME_FORMAT)}
    </DateBox>
)

Hour.propTypes = {
    hour: PropTypes.instanceOf(Date),
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
}

const Hours = ({ hours, selectedHour, onChange }) => (
    <Grid width='100%' columns={['1fr 1fr 1fr', '1fr 1fr 1fr 1fr 1fr']} gridRowGap='1em'>
        {hours.map((hour, index) => (
            <Cell key={`hour-${index}`}>
                <Hour
                  hour={hour}
                  isSelected={selectedHour && parseMoment(hour).isSame(parseMoment(selectedHour), 'minute')}
                  onSelect={onChange}
                />
            </Cell>
        ))}
    </Grid>
)

Hours.propTypes = {
    hours: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    selectedHour: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
}

Hours.defaultProps = {
    onChange: () => {},
}

export default Hours
