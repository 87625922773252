import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { themeColor } from '../../util/themeLenses'
import Hide from '../Hide'
import Copy from '../Copy'
import Flex from '../Flex'

import IconButton from '../IconButton'

const BannerWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${themeColor('darkMain')};
`

const DesktopPromoBanner = ({ text, linkText, linkUrl, campaign, id }) => (
    <BannerWrapper id={id} data-campaign={campaign}>
        <Flex h='56px' justifyContent='center' alignItems='center' p='8px 16px'>
            <Copy.span padding='0' color='white' fontWeight='book' fontSize='small'>
                {text}
                {' '}
                <a data-campaign={campaign} href={linkUrl} id='promo-bar-link'>
                    <Copy.span padding='0' color='accent' fontWeight='book' fontSize='small'>
                        {linkText}
                    </Copy.span>
                </a>
            </Copy.span>
        </Flex>
    </BannerWrapper>
)

DesktopPromoBanner.propTypes = {
    text: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string,
    campaign: PropTypes.string,
    id: PropTypes.string,
}

class MobilePromoBanner extends React.Component {
    state = {
        isShown: true,
    }

    hideBanner = this.hideBanner.bind(this)

    hideBanner () {
        this.setState({ isShown: false })
    }

    render () {
        const { text, linkText, linkUrl, campaign, id } = this.props
        const { isShown } = this.state

        return (
            isShown && <BannerWrapper id={id} data-campaign={campaign} dataTest='promo-banner'>
                <Flex h='56px' justifyContent='center' alignItems='center' p='8px 16px'>
                    <Copy.span padding='0' color='white' fontWeight='book' fontSize='small'>
                        {text}
                        {' '}
                        <a data-campaign={campaign} href={linkUrl} id='promo-bar-link-mobile'>
                            <Copy.span padding='0' color='accent' fontWeight='book' fontSize='small'>
                                {linkText}
                            </Copy.span>
                        </a>
                    </Copy.span>
                    <IconButton dataTest='button-close-banner' type='x' color='white' size='xLarge' onClick={this.hideBanner} />
                </Flex>
            </BannerWrapper>
        )
    }
}

MobilePromoBanner.propTypes = {
    text: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string,
    campaign: PropTypes.string,
    id: PropTypes.string,
}

const PromoBanner = ({ showOnMobile, ...restProps }) => (
    <div>
        <Hide xs sm md>
            <DesktopPromoBanner {...restProps} />
        </Hide>
        {showOnMobile && <Hide lg>
            <MobilePromoBanner {...restProps} />
        </Hide>}
    </div>
)

PromoBanner.propTypes = {
    showOnMobile: PropTypes.bool,
}

export default PromoBanner
