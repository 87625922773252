import PropTypes from 'prop-types'
import styled from 'styled-components'
import { placeholder } from 'polished'
import {
  themeFontSize,
  themeFontWeight,
  themeColor,
} from '../../util/themeLenses'
import Label from '../Label'

export { Label }

const mediaScreenMobile = (props) =>
  `@media screen and (max-width: ${props.theme.screens.medium})`

const DEFAULT_MOBILE_HEIGHT = '44px'
const inputBorderColor = ({ focus, valid, errorColor }) => {
    if (focus) {
        return themeColor('main')
    }
    if (!valid) {
        return themeColor(errorColor)
    }
    return themeColor('gray20')
}
const inputWith = ({width}) => width
const inputBorderWidth = ({ focus, valid }) => (focus || (!valid)) ? '2px' : '1px'
const fieldContainerPadding = ({ focus, valid }) => (focus || (!valid)) ? '0' : '1px'
const inputPaddingWithIcon = ({ paddingWithIcon }) => paddingWithIcon
const inputPadding = ({ padding }) => padding
const fontWeight = ({ fontWeight }) => themeFontWeight(fontWeight)
const fontSize = ({ fontSize }) => themeFontSize(fontSize)
const inputHeight = ({ height }) => height
const inputBackgroundColor = ({backgroundColor}) => themeColor(backgroundColor)
const inputPaddingTopMobile = ({leftIcon}) => leftIcon ? '0px' : '14px'
export const FieldInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: calc(${inputWith});
    background: ${inputBackgroundColor};
    min-width: calc(3 * ${inputPaddingWithIcon});
    border: ${inputBorderWidth} solid ${inputBorderColor};
    padding: ${fieldContainerPadding};
    border-radius: 2px;
    input:-webkit-autofill {
        background: ${inputBackgroundColor} !important;
        box-shadow: inset 0 0 0px 9999px ${inputBackgroundColor};
        -webkit-box-shadow: inset 0 0 0px 9999px ${inputBackgroundColor};
    }
`
FieldInputContainer.propTypes = {
    backgroundColor: PropTypes.string,
    width: PropTypes.string,
    focus: PropTypes.bool,
    valid: PropTypes.bool,
    paddingWithIcon: PropTypes.string,
}
FieldInputContainer.defaultProps = {
    width: '100%',
}
export const FieldContainer = styled.div`
    width: ${inputWith};
    position: relative;
`
FieldContainer.propTypes = {
    width: PropTypes.string,
}
export const IconContainer = styled.div`
    display: flex;
    flex-shrink: 0;
    height: ${inputHeight};
    align-items: center;
    width: ${inputPaddingWithIcon};
    justify-content: center;
    background: ${inputBackgroundColor};
    ${mediaScreenMobile} {
        height: ${DEFAULT_MOBILE_HEIGHT};
    }
`
IconContainer.propTypes = {
    active: PropTypes.bool,
    backgroundColor: PropTypes.string,
}
IconContainer.defaultProps = {
    backgroundColor: 'white',
}
export const IconRightContainer = styled(IconContainer)`
    ${mediaScreenMobile} {
        font-size: calc(2*${inputHeight}/5);
        display: none;
    }
`
export const IconRightContainerMobile = styled(IconRightContainer)`
    display: none;
    ${mediaScreenMobile} {
        display: flex;
    }
`
export const FieldInputStylized = styled.input`
    background: transparent !important;
    border: 0;
    margin: 0;
    padding-left: ${inputPadding};
    padding-right: ${inputPadding};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    color: ${themeColor('gray')};
    outline: unset;
    width: 100%;
    height: ${inputHeight};
    outline: none;
    text-align: ${({textAlign}) => textAlign};
    ${(props) => placeholder({
        'font-weight': themeFontWeight('regular')(props),
        'color': themeColor('gray60')(props),
    })}
    ::-webkit-file-upload-button {
        visibility: hidden;
        width: 0;
        margin: 0;
        background: ${themeColor('gray20')} !important;
        height: ${inputHeight};
    }
    &[type=file] {
        background: ${themeColor('gray20')} !important;
    }
    ${mediaScreenMobile} {
        ${placeholder({
            'color': 'transparent',
        })};
        padding-top: ${inputPaddingTopMobile};
        height: ${DEFAULT_MOBILE_HEIGHT};
        padding-right: 0;
    }
`
FieldInputStylized.propTypes = {
    leftIcon: PropTypes.any,
    fontColor: PropTypes.string,
    fontWeight: PropTypes.string,
    textAlign: PropTypes.string,
    fontSize: PropTypes.string,
    height: PropTypes.string,
    errorColor: PropTypes.string,
    padding: PropTypes.string,
    placeholder: PropTypes.string,
}
