import React from 'react'
import PropTypes from 'prop-types'
import Flex from '../Flex'
import Box from '../Box'
import Button from '../Button'
import Copy from '../Copy'

const GenericErrorModalContent = ({ message, buttonLabel, onClick }) => (
    <Flex flexDirection='column'>
        <Box mb='64px'>
            <Copy size='medium' fontWeight='regular'>
                { message }
            </Copy>
        </Box>
        <Button onClick={onClick}>
            { buttonLabel }
        </Button>
    </Flex>
)

GenericErrorModalContent.propTypes = {
    message: PropTypes.any,
    buttonLabel: PropTypes.any,
    onClick: PropTypes.func,
}

export default GenericErrorModalContent
