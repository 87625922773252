export function navigateTo(url) {
  document.location.assign(url)
}

export function reload(forceReload = false) {
  document.location.reload(forceReload)
}

export function open(url, options = { target: '_blank' }) {
  window.open(url, options.target)
}
