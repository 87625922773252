import {
  style,
  space,
  letterSpacing,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  flex,
  order,
  alignSelf,
  marginRight,
  marginLeft,
  marginTop,
  marginBottom,
  minWidth,
  maxWidth,
  minHeight,
  padding,
  borderRadius,
  borderStyle,
  borderWidth,
  display,
  size,
  overflow,
  alignContent,
  gridAutoColumns,
  gridAutoFlow,
  gridAutoRows,
  gridColumnGap,
  gridGap,
  gridRowGap,
  gridTemplateAreas,
  zIndex,
} from 'styled-system'

const wordBreak = style({
  prop: 'wordBreak',
  key: 'wordBreak',
  cssProperty: 'word-break',
})

const borderColor = style({
  prop: 'borderColor',
  key: 'colors',
  cssProperty: 'border-color',
})

const backgroundColor = style({
  prop: 'backgroundColor',
  key: 'colors',
  cssProperty: 'background-color',
})

const background = style({
  prop: 'background',
  alias: 'bg',
  key: 'colors',
  cssProperty: 'background',
})

const width = style({
  prop: 'width',
  key: 'width',
  alias: 'w',
  cssProperty: 'width',
})

const color = style({
  prop: 'fontColor',
  alias: 'color',
  key: 'colors',
  cssProperty: 'color',
})

const align = style({
  prop: 'textAlign',
  alias: 'align',
  cssProperty: 'textAlign',
})

const lines = style({
  prop: 'lines',
  alias: 'lines',
  cssProperty: '-webkit-line-clamp',
})

const boxOrient = style({
  prop: 'boxOrient',
  alias: 'boxOrient',
  cssProperty: '-webkit-box-orient',
})

const heightSize = style({
  prop: 'size',
  alias: 'heightSize',
  key: 'font.sizes',
  cssProperty: 'height',
})

const fontSize = style({
  prop: 'fontSize',
  alias: 'size',
  key: 'font.sizes',
  cssProperty: 'fontSize',
})

const fontWeight = style({
  prop: 'fontWeight',
  alias: 'weight',
  key: 'font.weights',
  cssProperty: 'fontWeight',
})

const lineHeight = style({
  prop: 'lineHeight',
  alias: 'lineHeight',
  key: 'font.lineHeight',
  cssProperty: 'lineHeight',
})

const lineHeightCorresponsive = style({
  prop: 'fontSize',
  alias: 'size',
  key: 'font.lineHeight',
  cssProperty: 'lineHeight',
})

const transform = style({
  prop: 'textTransform',
  alias: 'transform',
  cssProperty: 'textTransform',
})

const decoration = style({
  prop: 'textDecoration',
  alias: 'decoration',
  cssProperty: 'textDecoration',
})

const height = style({
  prop: 'height',
  alias: 'h',
  cssProperty: 'height',
})

const float = style({
  prop: 'float',
  key: 'float',
  cssProperty: 'float',
})

const gridTemplateColumns = style({
  prop: 'gridTemplateColumns',
  alias: 'columns',
})

const msGridTemplateColumns = style({
  prop: 'gridTemplateColumns',
  alias: 'columns',
  cssProperty: '-ms-grid-columns',
})

const gridTemplateRows = style({
  prop: 'gridTemplateRows',
  alias: 'rows',
})

const msGridTemplateRows = style({
  prop: 'gridTemplateRows',
  alias: 'rows',
  cssProperty: '-ms-grid-rows',
})

export {
  // styled-system
  space,
  letterSpacing,
  borderColor,
  borderRadius,
  borderStyle,
  borderWidth,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  flex,
  order,
  backgroundColor,
  alignSelf,
  marginBottom,
  marginTop,
  marginRight,
  marginLeft,
  padding,
  minWidth,
  display,
  overflow,
  minHeight,
  maxWidth,
  size,
  alignContent,
  gridAutoColumns,
  gridAutoFlow,
  gridAutoRows,
  gridColumnGap,
  gridGap,
  gridRowGap,
  gridTemplateAreas,
  zIndex,
  // own
  background,
  height,
  heightSize,
  width,
  color,
  align,
  lines,
  boxOrient,
  fontSize,
  fontWeight,
  lineHeight,
  lineHeightCorresponsive,
  transform,
  decoration,
  float,
  gridTemplateColumns,
  msGridTemplateColumns,
  gridTemplateRows,
  msGridTemplateRows,
  wordBreak,
}
