import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { renderRecaptchaScript, isReady, execute } from './renderRecaptchaScript'

let readyCheck

const ReCaptcha = ({
  elementID,
  callback,
  sitekey,
  action,
  isReady,
}) => {
    const captchaReady = isReady()
    const [ ready, setReady ] = useState(isReady())

    useEffect(() => {
      renderRecaptchaScript(sitekey)
    }, [sitekey])

    useEffect(() => {
        if (ready) {
          execute({
            ready,
            sitekey,
            action,
            callback,
          })
        } else {
          const readyCheck = setInterval(() => {
            if (isReady()) {
              setReady(true)
              clearInterval(readyCheck)
            }
          }, 1000)
        }
      return () => clearInterval(readyCheck)
    }, [action, callback, captchaReady, isReady, ready, sitekey])

    return <div id={elementID} data-action={action} className='g-recaptcha' />
}

ReCaptcha.propTypes = {
  elementID: PropTypes.string,
  callback: PropTypes.func,
  sitekey: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  isReady: PropTypes.func.isRequired,
}

ReCaptcha.defaultProps = {
  elementID: 'g-recaptcha',
  verifyCallbackName: 'verifyCallback',
  isReady,
}

export default ReCaptcha
