import styled from 'styled-components'
import Label from '../Label'
import {
  themeColor,
  themeFontSize,
  themeFontWeight,
  themeFontFamily,
} from '../../util/themeLenses'

const mediaScreenMobile = props => `@media screen and (max-width: ${props.theme.screens.medium})`

const selectorBorderColor = ({valid, changed, errorColor}) => !valid && changed ? themeColor(errorColor) : themeColor('gray20')
const selectorBorderWidth = ({valid, changed}) => !valid && changed ? `2px` : '1px'
const selectorPadding = ({valid, changed}) => !valid && changed ? `0` : '1px'
const inputHeight = ({ height }) => height

export const Container = styled.div`
  width: 100%;
  font-family: '${themeFontFamily()}';
`

export { Label }

const DEFAULT_MOBILE_HEIGHT = '44px'
const PADDING = '8px'

export const NativeSelector = styled.select`
    display: block;
    background: ${({isDisabled, background}) => isDisabled ? themeColor('lightGray20') : themeColor(background)};
    position: relative;
    height: 2.8em;
    font-size: 0.8em;
    width: 100%;
    border-radius: 2px;
    outline: none;
    border: 1px solid ${themeColor('lightGray40')};
    color: ${themeColor('gray')};
    font-weight: ${themeFontWeight('regular')};
    height: ${DEFAULT_MOBILE_HEIGHT};
    padding-left: ${PADDING};
    padding-right: ${PADDING};
    font-size: ${themeFontSize('small')};
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='rgba(0,0,0, 0.6)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: ${PADDING};
    &:disabled{
        background: ${themeColor('lightGray20')};
        color: ${themeColor('gray60')};
        cursor: not-allowed;
    }
`

export const Selector = styled.div`
    background: ${({isDisabled, background}) => isDisabled ? themeColor('lightGray20') : themeColor(background)};
    position: relative;
    border-color: ${selectorBorderColor};
    border-style: solid;
    padding: ${selectorPadding};
    border-width: ${selectorBorderWidth};
    border-radius: ${({isOpen}) => isOpen ? '2px 2px 0 0' : '2px'};
    color: ${themeColor('main')};
    text-align: left;
    box-shadow: ${({isOpen}) => isOpen ? '0px 4px 12px 0px rgba(0,0,0,0.1)' : 'none'};
    cursor: ${({isDisabled}) => isDisabled ? 'not-allowed' : 'pointer'};
`

export const SelectorOptionDisabled = styled.div`
    position: relative;
    text-align: left;
    padding: 12px 8px;
    width: 100%;
    background: ${themeColor('lightGray40')};
    outline: none;
    cursor: not-allowed;

    &:before{
        content: '';
        position: absolute;
        top: 0;
        height: 1px;
        background-color: ${themeColor('lightGray40')};
        width: calc(100% - 16px);
    }
`

export const SelectorOption = styled(SelectorOptionDisabled)`
    background: ${themeColor('white')};
    cursor: pointer;
    &:focus,
    &:hover {
        background-color: ${themeColor('lightGray20')};
    }
`

export const SelectorOptionsBlock = styled.div`
    position: absolute;
    margin-left: -1px;
    width: calc(100% + 2px);
    background: ${themeColor('white')};
    border-radius: 0 0 2px 2px;
    border: 1px solid ${themeColor('lightGray40')};
    border-top: 0px;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1000;
    font-size: ${themeFontSize('small')};
    font-weight: ${themeFontWeight('light')};
    color: ${themeColor('gray')};
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.1);
`

export const Selected = styled.div`
    overflow: hidden;
    height: ${inputHeight};
    padding: 0 12px;
    text-align: left;
    font-size: ${themeFontSize('small')};
    font-weight: ${themeFontWeight('regular')};
    color: ${themeColor('gray')};
    outline: none;

    display: flex;
    align-items: center;
    justify-content: space-between;

    ${mediaScreenMobile} {
        height: ${DEFAULT_MOBILE_HEIGHT};
    }
`

export const SelectedContent = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`
