import moment from 'moment'

export function save(key, jsonData, expirationMin) {
  var record = { value: jsonData, timestamp: expirationMin || '' }
  window.localStorage.setItem(key, JSON.stringify(record))
  return jsonData
}

export function get(key) {
  if (typeof window === 'undefined') {
    return false
  }
  const record = window.localStorage.getItem(key)
  if (!record) {
    return false
  }

  const recordParsed = JSON.parse(record)
  if (recordParsed.timestamp) {
    // TODO: Use timestamp instead of date
    const date = moment.utc(recordParsed.timestamp, 'YYYY-MM-DD HH:mm:ss')
    return date.diff(moment()) > 0 && recordParsed.value
  } else {
    return recordParsed.value
  }
}

export function remove(key) {
  window.localStorage.removeItem(key)
}
