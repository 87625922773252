import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '../Button'
import Loader from '../Loader'

const withMargin = (props) => (props.withMargin ? '0.5em' : '0')

const TextWrapper = styled.span`
  margin-left: ${withMargin};
  pointer-events: none;
`

TextWrapper.propTypes = {
  withMargin: PropTypes.bool,
}

TextWrapper.defaultProps = {
  withMargin: false,
}

const LoaderButton = ({ children, loading, disabled, ...restProps }) => (
  <Button disabled={loading || disabled} {...restProps}>
    {loading && <Loader color="white" />}
    <TextWrapper withMargin={loading}>{children}</TextWrapper>
  </Button>
)

LoaderButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
}

export default LoaderButton
