import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fontSize, color } from '../../util/styles'
import { FONT_SIZES, COLORS} from '../../util/theme'


const IconStyled = styled.i`
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:before {
        margin: 0;
        width: auto;
        font-style: normal;
        font-weight: normal;
        ${fontSize}
        text-decoration: inherit;
        text-align: center;
        ${color}
    }
`

IconStyled.propTypes = {
    type: PropTypes.string,
    size: PropTypes.oneOfType([
        PropTypes.oneOf(FONT_SIZES),
        PropTypes.arrayOf(PropTypes.oneOf(FONT_SIZES)),
    ]),
    color: PropTypes.oneOfType([
        PropTypes.oneOf(['inherit', ...COLORS]),
        PropTypes.arrayOf(PropTypes.oneOf(COLORS)),
    ]),
}

const Icon = ({type, dateTest, ...props}) => <IconStyled data-test={dateTest} className={`icon-${type}`} {...props} />

Icon.propTypes = {
    type: PropTypes.string,
    size: PropTypes.oneOfType([
        PropTypes.oneOf(FONT_SIZES),
        PropTypes.arrayOf(PropTypes.oneOf(FONT_SIZES)),
    ]),
    color: PropTypes.oneOfType([
        PropTypes.oneOf(['inherit', ...COLORS]),
        PropTypes.arrayOf(PropTypes.oneOf(COLORS)),
    ]),
    align: PropTypes.string,
    dateTest: PropTypes.string,
}

IconStyled.defaultProps = {
    color: 'main',
    size: 'small',
    align: 'bottom',
}

export { FONT_SIZES, COLORS }

export default Icon
