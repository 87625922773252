import { getFractionDigits } from './Config'

function toCurrency (intl, qty = { amount: 0, currency: 'EUR' }, formatOptions = {}, epConfig = null) {
    const fractionDigits = getFractionDigits(qty.currency, epConfig)
    return intl.formatNumber(qty.amount, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
        ...formatOptions,
    })
}

export default toCurrency
