import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import moment from 'moment-timezone'
import { parseMoment, toParts, formatDate, formatTimestamp, toMillis, DateConstants } from '@elparking/utils'
import Copy from '../Copy'
import useConfig from '../../hooks/useConfig'
import { getLocalizedSrc } from '../Image'
import ExpandedCalendar from './ExpandedCalendar'

const { DATETIME_FORMAT } = DateConstants

const toTimestamp = (date, timeZone) => parseInt(formatTimestamp(moment.tz(formatDate(date, DATETIME_FORMAT), timeZone)))
const toDate = (timestamp, timeZone) => new Date(...toParts(parseMoment(toMillis(timestamp)).tz(timeZone)))
const Calendar = ({ days, hours, value, timeZone, onChangeDay, onChangeHour, ...props }) => {
    const dates = useMemo(() => days.map((date) => toDate(date, timeZone)), [days, timeZone])
    const hoursPerDay = useMemo(() => hours.map((date) => toDate(date, timeZone)), [hours, timeZone])
    const selectedDate = value && toDate(value, timeZone)
    function handleChangeDay (date) {
        // NOTE: react-day-picker sets day hour to 12:00, so we need to find original one matching same day
        const originalDate = dates.find((activeDate) => parseMoment(activeDate).isSame(parseMoment(date), 'day'))

        onChangeDay(toTimestamp(originalDate, timeZone))
    }

    function handleChangeHour (date) {
        onChangeHour(toTimestamp(date, timeZone))
    }

    const country = useConfig('country')
    const flavor = useConfig('flavor')
    const excludedFlavors = useConfig('excludedFlavors')
    return (
        <div>
            <Copy padding='0 0 0 1em' fontWeight='book' fontSize='xLarge'>
                <FormattedMessage defaultMessage='Selecciona fecha y hora' />
            </Copy>
            <ExpandedCalendar
              days={dates}
              hours={hoursPerDay}
              value={selectedDate}
              onChangeDay={handleChangeDay}
              onChangeHour={handleChangeHour}
              leftArrowImage={getLocalizedSrc('/images/left-arrow.svg', country, flavor, excludedFlavors)}
              rightArrowImage={getLocalizedSrc('/images/right-arrow.svg', country, flavor, excludedFlavors)}
              {...props}
            />
        </div>
    )
}

Calendar.propTypes = {
    days: PropTypes.arrayOf(PropTypes.number).isRequired,
    hours: PropTypes.arrayOf(PropTypes.number).isRequired,
    value: PropTypes.number,
    onChangeDay: PropTypes.func,
    onChangeHour: PropTypes.func,
    timeZone: PropTypes.string.isRequired,
}

Calendar.defaultProps = {
    onChangeDay: () => {},
    onChangeHour: () => {},
    timeZone: 'Europe/Madrid',
}

export default Calendar
