import styled from 'styled-components'
import {
  style,
  gridArea,
  display,
  marginBottom,
  marginTop,
  textAlign,
  backgroundColor,
  space,
} from 'styled-system'

export const gridColumnStart = style({
  prop: 'gridColumnStart',
  alias: 'left',
  cssProperty: 'grid-column-start',
})

export const msGridColumnStart = style({
  prop: 'gridColumnStart',
  alias: 'left',
  cssProperty: '-ms-grid-column',
})

export const gridColumnEnd = style({
  prop: 'gridColumnEnd',
  alias: 'width',
  cssProperty: 'grid-column-end',
  transformValue: (width) => `span ${width}`,
})

export const msGridColumnEnd = style({
  prop: 'gridColumnEnd',
  alias: 'width',
  cssProperty: '-ms-grid-column-span',
})

export const gridRowStart = style({
  prop: 'gridRowStart',
  alias: 'top',
  cssProperty: 'grid-row-start',
})

export const msGridRowStart = style({
  prop: 'gridRowStart',
  alias: 'top',
  cssProperty: '-ms-grid-row',
})

export const gridRowEnd = style({
  prop: 'gridRowEnd',
  alias: 'height',
  cssProperty: 'grid-row-end',
  transformValue: (height) => `span ${height}`,
})

export const msGridRowEnd = style({
  prop: 'gridRowEnd',
  alias: 'height',
  cssProperty: '-ms-grid-row-span',
})

const middle = ({ middle }) =>
  middle &&
  `
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-self: stretch;
  `

const right = ({ right }) =>
right &&
`
  display: inline-flex;
  flex-flow: column wrap;
  justify-content: center;
  justify-self: stretch;
  align-items: flex-end;
`

const Cell = styled.div`
  ${backgroundColor}
  ${middle}
  ${right}
  ${gridColumnStart}
  ${msGridColumnStart}
  ${gridColumnEnd}
  ${msGridColumnEnd}
  ${gridRowStart}
  ${msGridRowStart}
  ${gridRowEnd}
  ${msGridRowEnd}
  ${gridArea}
  ${display}
  ${marginBottom}
  ${marginTop}
  ${textAlign}
  ${space}
`

export default Cell
