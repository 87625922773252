import styled from 'styled-components'
import {
    width,
    height,
    space,
    marginTop,
    marginBottom,
    background
} from '../../util/styles'

const HR = styled.hr`
    ${background}
    border: 0;
    ${space}
    ${width}
    ${height}
    ${marginTop}
    ${marginBottom}
`

HR.vertical = styled.div`
    ${background}
    border: 0;
    display: inline-block;
    ${space}
    ${width}
    ${height}
`

HR.defaultProps = {
    background: 'lightGray40',
    width: '100%',
    m: '1em 0',
    height: '1px',
}

HR.vertical.defaultProps = {
    background: 'lightGray',
    width: '1px',
    m: '0',
    height: '1em',
}

export default HR
