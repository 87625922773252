import { useState, useCallback, useEffect, useRef } from 'react'

const initialResponse = { result: null, error: null }

export default function useApi(apiCall) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(initialResponse)

  let cancelled = useRef(false)

  const wrapCancelled = (fn) => (!cancelled.current ? fn : () => {})

  const resetResponse = useCallback(() => {
    setResponse(initialResponse)
  }, [setResponse])

  const sendRequest = useCallback(
    (request, headers) => {
      async function performApiCall(request, headers) {
        wrapCancelled(setLoading)(true)

        try {
          const response = await apiCall(request, headers)

          wrapCancelled(setResponse)(response)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
          wrapCancelled(setError)(error)
        } finally {
          wrapCancelled(setLoading)(false)
        }
      }

      performApiCall(request, headers)
    },
    [apiCall]
  )

  useEffect(() => {
    return () => {
      cancelled.current = true
    }
  }, [cancelled])

  return {
    response,
    loading,
    error,
    sendRequest,
    resetResponse,
  }
}
