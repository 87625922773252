import styled from 'styled-components'
import { themeMediaQueryMinMax } from '../../util/themeLenses'
import { width } from '../../util/styles'

export const hidden = key => props => {
    const media = themeMediaQueryMinMax(key)(props)
    return props[key]
        ? { [media]: { display: 'none !important' } }
        : null
}

export const xs = hidden('xs')
export const sm = hidden('sm')
export const md = hidden('md')
export const lg = hidden('lg')

const Hide = styled.div([width],  
    xs,
    sm,
    md,
    lg
)

Hide.span = styled.span([],
    xs,
    sm,
    md,
    lg
)

export default Hide