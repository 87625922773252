import { useContext } from 'react'
import { ConfigContext } from '../providers/EPConfig'

function useConfig(property = null, defaultValue) {
  const epConfig = useContext(ConfigContext)
  if (!epConfig) {
    throw new Error('Config provider has not been loaded')
  }
  if (
    property &&
    (epConfig[property] === null || epConfig[property] === undefined) &&
    defaultValue === undefined
  ) {
    throw new Error(`Config provider has not the property: ${property}`)
  }
  return property ? epConfig[property] || defaultValue : epConfig
}

export default useConfig
