
export const once = (func) => {
    let alreadyCalled = false

    return (...args) => {
        if (!alreadyCalled) {
            func(...args)
            alreadyCalled = true
        }
    }
}

export default once