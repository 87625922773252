import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import DayPicker from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'

import useConfig from '../../hooks/useConfig'
import { themeFontSize, themeFontWeight, themeColor } from '../../util/themeLenses'
import { DateConstants, formatDate } from '@elparking/utils'
import leftArrow from './images/left-arrow.svg'
import rightArrow from './images/right-arrow.svg'

const MONTH_LONG_FORMAT = DateConstants && DateConstants.MONTH_LONG_FORMAT
const DAY_OF_MONTH_NUMERIC_FORMAT = DateConstants && DateConstants.DAY_OF_MONTH_NUMERIC_FORMAT
const DATE_FORMAT = DateConstants && DateConstants.DATE_FORMAT

const StyledDayPicker = styled(DayPicker)`
    color: ${themeColor('darkMain')};
    font-weight: ${themeFontWeight('regular')};
    display: flex;
    align-items: center;

    .DayPicker-Month {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .DayPicker-wrapper {
        outline: none;
        width: 100%;
    }

    .DayPicker-Caption {
        margin-bottom: 1em;
        font-weight: ${themeFontWeight('medium')};
        text-align: center;
        font-size: ${themeFontSize('xLarge')};
        line-height: 2.5em;
    }

    .DayPicker-NavButton--prev {
        left: 0;
        background-image: url('${(props) => props.leftArrowImage || leftArrow}');
    }

    .DayPicker-NavButton--next {
        background-image: url('${(props) => props.rightArrowImage || rightArrow}');
        right: 0;
    }

    .DayPicker-NavButton {
        width: 2.5em;
        height: 2.5em;
        outline: none;
    }

    .DayPicker-WeekdaysRow,
    .DayPicker-Week {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .DayPicker-Weekday abbr[title] {
        border-bottom: none;
        text-decoration: none;
    }

    .DayPicker-Day,
    .DayPicker-Weekday {
        display: block;
        width: 2.5em;
        height: 2.5em;
        line-height: 2.5em;
        padding: 0;
        margin: 0;
        margin-bottom: 0.5em;
    }

    .DayPicker-Weekday {
        font-weight: ${themeFontWeight('book')};
        color: ${themeColor('gray')};
    }

    .DayPicker-Day {
        outline: none;
        text-align: center;
        border-radius: 50%;

        color: ${themeColor('gray')};
        border: 1px solid ${themeColor('main')};
    }

    &:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover,
    &:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover {
        background-color: ${themeColor('main')};
        color: ${themeColor('white')};
    }

    .DayPicker-Day--inactive,
    .DayPicker-Day--disabled {
        color: ${themeColor('gray20')};
        background-color: inherit;
    }

    .DayPicker-Day--inactive {
        position: relative;
        color: ${themeColor('gray20')};
    }

    .DayPicker-Day--disabled {
        border: none;
        pointer-events: none;
        position: relative;

        &:after{
            content: '';
            position :absolute;
            left: 2em;
            top: 0;
            border-top: 1px solid ${themeColor('gray20')};
            width: 100%;
            transform: rotate(120deg);
            transform-origin: 0% 0%;
        }
    }

    .DayPicker-Day--today {
        background: ${themeColor('lightGray20')};
        font-weight: ${themeFontWeight('medium')};
    }

    .DayPicker-Day--today.DayPicker-Day--outside {
        font-weight: ${themeFontWeight('regular')};
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
    .DayPicker-Day--selected {
        color: ${themeColor('white')};
        background: ${themeColor('main')};
    }
`

const formatMonthTitle = (date, locale = 'es') => moment(date).locale(locale).format(MONTH_LONG_FORMAT)

export const DayItem = ({ day }) => (
    <span data-test={`calendar-day-${formatDate(day, DATE_FORMAT)}`}>
        {formatDate(day, DAY_OF_MONTH_NUMERIC_FORMAT)}
    </span>
)

DayItem.propTypes = {
    day: PropTypes.instanceOf(Date),
}

const localeUtils = {
    ...MomentLocaleUtils,
    formatMonthTitle,
}

const Days = ({
    selectedDay,
    onChange,
    ...props
}) => {
    const country = useConfig('country')
    const flavor = useConfig('flavor')
    return (<StyledDayPicker
      locale={moment.locale()}
      localeUtils={localeUtils}
      formatMonthTitle={formatMonthTitle}
      onDayClick={onChange}
      selectedDays={selectedDay}
      showOutsideDays
      renderDay={(day) => <DayItem day={day} />}
      country={country}
      flavor={flavor}
      {...props}
    />)
}

Days.propTypes = {
    onChange: PropTypes.func,
    selectedDay: PropTypes.instanceOf(Date),
}

Days.defaultProps = {
    onChange: () => {},
}

export default Days
