import React from 'react'
import PropTypes from 'prop-types'
import useConfig from '../hooks/useConfig'
import RemoteConfigProvider from './RemoteConfigProvider'
import { getRemoteConfig } from '../services/remoteConfig'

function PromoBannerProvider({ children }) {
  const firebaseAppId = useConfig('firebaseAppId')
  const firebaseApiKey = useConfig('firebaseApiKey')
  const firebaseAuthDomain = useConfig('firebaseAuthDomain')
  const firebaseProjectId = useConfig('firebaseProjectId')
  const firebaseStorageBucket = useConfig('firebaseStorageBucket')
  const firebaseMessagingSenderId = useConfig('firebaseMessagingSenderId')

  return (
    <RemoteConfigProvider
      remoteConfig={getRemoteConfig({
        apiKey: firebaseApiKey,
        authDomain: firebaseAuthDomain,
        projectId: firebaseProjectId,
        storageBucket: firebaseStorageBucket,
        messagingSenderId: firebaseMessagingSenderId,
        appId: firebaseAppId,
      })}
    >
      {children}
    </RemoteConfigProvider>
  )
}

PromoBannerProvider.propTypes = {
  children: PropTypes.node,
}

export default PromoBannerProvider
