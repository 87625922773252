import { useContext } from 'react'
import PropTypes from 'prop-types'

import RemoteConfigContext from './RemoteConfigContext'

function RemoteConfigConsumer({ value, children }) {
  const values = useContext(RemoteConfigContext)

  if (!values) {
    throw new Error(
      'Could not find required `values` object. Please, wrap consumer inside `RemoteConfigProvider`'
    )
  }

  const remoteValue = values[value]

  if (!remoteValue) {
    return null
  }

  return children(remoteValue)
}

RemoteConfigConsumer.propTypes = {
  value: PropTypes.string,
  children: PropTypes.func,
}

export default RemoteConfigConsumer
