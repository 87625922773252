import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import EPModal from '../EPModal'
import Copy from '../Copy'
import Icon from '../Icon'
import ScreenSwitch from '../ScreenSwitch'

import {
    FieldContainer,
} from '../Input/layout'

import {
    Label,
    SelectorOption,
    Selector,
    SelectorOptionsBlock,
    Selected,
    SelectedContent,
    Container,
    SelectorOptionDisabled,
    NativeSelector,
} from './layout'

const DEFAULT_FONT_SIZE = 'small'
const DEFAULT_HEIGHT = '36px'

const findOptionByValue = (options, value) => {
    if (!value || !options) {
        return null
    }
    return options.find((item) => {
        return value === item.value
    })
}

export function getStateFromProps ({ value, options }, { selectedOption }) {
    if (!selectedOption || (value !== selectedOption.value)) {
        if (value === undefined) {
            return null
        }

        if (value === '' && !!selectedOption) {
            return {
                selectedOption: null,
                isOpen: false,
            }
        }

        const optionToBeSelected = findOptionByValue(options, value)
        if (!optionToBeSelected || (selectedOption && optionToBeSelected.value === selectedOption.value)) {
            return null
        }

        return {
            selectedOption: optionToBeSelected,
        }
    }
    return null
}

const IconPlaceholder = () => <Icon type='arrow-down' color='gray60' />

class Dropdown extends React.Component {
    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
            value: PropTypes.any,
            isDisabled: PropTypes.bool,
        })).isRequired,
        disabled: PropTypes.bool,
        defaultValue: PropTypes.string,
        label: PropTypes.string,
        fontSize: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
        nativePlaceholder: PropTypes.string,
        renderPlaceholder: PropTypes.func,
        errorColor: PropTypes.string,
        background: PropTypes.string,
        onChange: PropTypes.func,
        onlyDesktop: PropTypes.bool,
        renderOptionNative: PropTypes.func,
        renderOption: PropTypes.func,
        renderSelected: PropTypes.func,
        selectorOptionElement: PropTypes.elementType,
        selectorElement: PropTypes.elementType,
        selectorOptionsBlockElement: PropTypes.elementType,
        nativeSelectorComponent: PropTypes.elementType,
        selectedElement: PropTypes.elementType,
        selectedContentElement: PropTypes.elementType,
        iconPlaceholderElement: PropTypes.elementType,
        containerElement: PropTypes.elementType,
        screenSwitcher: PropTypes.elementType,
        valid: PropTypes.bool,
        changed: PropTypes.bool,
        focusOnClick: PropTypes.bool,
        showLabel: PropTypes.bool,
        dataTest: PropTypes.string,
        // eslint-disable-next-line react/no-unused-prop-types
        value: PropTypes.any,
        open: PropTypes.bool,
        showNativePlaceholder: PropTypes.bool,
        forceFocus: PropTypes.bool,
    }

    onOpen = this.onOpen.bind(this)
    onClose = this.onClose.bind(this)
    onChangeValue = this.onChangeValue.bind(this)
    onClickOptionNative = this.onClickOptionNative.bind(this)
    clear = this.clear.bind(this)

    state = {
        selectedOption: findOptionByValue(this.props.options, this.props.defaultValue) || findOptionByValue(this.props.options, this.props.value),
        isOpen: this.props.open,
        value: this.props.value,
    }

    static defaultProps = {
        open: false,
        label: null,
        defaultValue: '',
        width: null,
        background: 'white',
        showLabel: false,
        focusOnClick: true,
        showNativePlaceholder: true,
        value: undefined,
        selectedOption: null,
        onlyDesktop: false,
        nativePlaceholder: 'Selecciona uno',
        iconPlaceholderElement: IconPlaceholder,
        selectorOptionElement: SelectorOption,
        selectorElement: Selector,
        selectorOptionsBlockElement: SelectorOptionsBlock,
        selectedElement: Selected,
        nativeSelectorComponent: NativeSelector,
        selectedContentElement: SelectedContent,
        containerElement: Container,
        fontSize: DEFAULT_FONT_SIZE,
        height: DEFAULT_HEIGHT,
        renderOptionNative: ({ label }) => label.toString(),
        renderSelected: ({ label }) => label.toString(),
        renderPlaceholder: () => <FormattedMessage defaultMessage='Selecciona uno' />,
        renderOption: ({ label }) => label.toString(),
        onChange: () => {},
        screenSwitcher: ScreenSwitch,
    }

    componentDidUpdate ({ forceFocus }) {
        if (!forceFocus && this.props.forceFocus) {
            this.focus()
        }
    }

    componentDidMount () {
        if (this.props.forceFocus) {
            this.focus()
        }
    }

    focus () {
        this.onOpen()
        this.nativeSelector && this.nativeSelector.focus()
    }

    onOpen () {
        const { disabled, focusOnClick } = this.props
        if (disabled) {
            return
        }

        !this.state.isOpen && this.setState(
            {
                isOpen: true,
            },
            () => {
                focusOnClick && this.optionElements && this.optionElements[0] && this.optionElements[0].focus()
            },
        )
    }

    onClose () {
        this.state.isOpen && this.setState({
            isOpen: false,
        })
    }

    clear () {
        this.setState({
            selectedOption: null,
            isOpen: false,
        })
    }

    onChangeValue (option) {
        this.setState(() => ({
            selectedOption: option,
            isOpen: false,
        }), () => {
            this.props.onChange(option)
        })
    }

    onClickOptionNative (event) {
        const value = event.target.value
        const selectedOption = findOptionByValue(this.props.options, value)

        this.onChangeValue(selectedOption)
    }

    static getDerivedStateFromProps (props, prevState) {
        return getStateFromProps(props, prevState)
    }

    renderOptions () {
        const {
            selectorOptionElement: SelectorOptionElement,
            options,
        } = this.props

        this.optionElements = []

        return options.map((option, index) => {
            if (option.isDisabled) {
                return (
                    <SelectorOptionDisabled key={index}>
                        {this.props.renderOption(option)}
                    </SelectorOptionDisabled>
                )
            }
            return (
                <SelectorOptionElement
                  dataTest='dropdown-option'
                  ref={(el) => { this.optionElements[index] = el }}
                  tabIndex={0}
                  key={index}
                  onKeyPress={({key}) => (key === 'Enter' && this.onChangeValue(option))}
                  onClick={() => this.onChangeValue(option)}
                >
                    {this.props.renderOption(option)}
                </SelectorOptionElement>
            )
        })
    }

    renderSelected (value) {
        const {
            renderSelected,
            renderPlaceholder,
            disabled,
        } = this.props

        return (value
            ? renderSelected(value)
            : renderPlaceholder({ disabled }))
    }

    renderNative () {
        const { defaultValue, showNativePlaceholder, background, nativeSelectorComponent: NativeSelectorComponent, nativePlaceholder, options, renderOptionNative, disabled } = this.props
        const { selectedOption } = this.state
        const value = selectedOption ? selectedOption.value : defaultValue

        return (
            <NativeSelectorComponent
              background = {background}
              ref={(el) => { this.nativeSelector = el }}
              defaultValue={value}
              onChange={this.onClickOptionNative}
              disabled={disabled}>
                {showNativePlaceholder && <option key='placeholder' value='' disabled>{nativePlaceholder}</option>}
                {options.map((option, index) => {
                    return (<option key={index} value={option.value}>{renderOptionNative(option)}</option>)
                })}
            </NativeSelectorComponent>
        )
    }

    renderNative = this.renderNative.bind(this)

    renderDropdown () {
        const {
            selectorElement: SelectorElement,
            iconPlaceholderElement: IconPlaceholderElement,
            selectedElement: SelectedElement,
            selectedContentElement: SelectedContentElement,
            selectorOptionsBlockElement: SelectorOptionBlockElement,
            disabled,
            errorColor,
            background,
            changed,
            valid,
            width,
            showLabel,
            label,
            nativePlaceholder: placeholder,
            screenSwitcher: ScreenSwitcher,
            fontSize,
            height,
            renderPlaceholder,
        } = this.props

        const { isOpen, selectedOption } = this.state

        return (
            <FieldContainer width={width}>
                {showLabel && <Label
                  width={width}
                  fontSize={fontSize}
                              >{label || placeholder}
                </Label>}
                <SelectorElement
                  isOpen={isOpen}
                  isDisabled={disabled}
                  changed={changed}
                  valid={valid}
                  errorColor={errorColor}
                  background={background}
                >
                    <SelectedElement
                      dataTest='dropdown-selected'
                      tabIndex={0}
                      ref={(el) => { this.selectedElement = el }}
                      onFocus={this.onOpen}
                      height={height}
                      onClick={this.onOpen}
                    >
                        <SelectedContentElement>
                            {this.renderSelected(selectedOption)}
                        </SelectedContentElement>
                        <IconPlaceholderElement />
                    </SelectedElement>
                    <ScreenSwitcher
                      other={() =>
                          isOpen && <EPModal
                            padding='0'
                            title={<Copy fontColor='gray' padding='1em 0 0 1em' fontSize='large'>
                                { label || renderPlaceholder({ disabled }) }
                            </Copy>}
                            isShown
                            onClose={this.onClose}>
                              <>{this.renderOptions()}</>
                          </EPModal>
                      }
                      medium={() => isOpen &&
                          <SelectorOptionBlockElement onMouseLeave={this.onClose}>
                              {this.renderOptions()}
                          </SelectorOptionBlockElement>
                      }
                    />
                </SelectorElement>
            </FieldContainer>
        )
    }
    renderDropdown = this.renderDropdown.bind(this)

    render () {
        const { containerElement: ContainerElement, screenSwitcher: ScreenSwitcher, onlyDesktop } = this.props

        if (onlyDesktop) {
            return (
                <ContainerElement onMouseLeave={this.onClose} data-test={this.props.dataTest}>
                    {this.renderDropdown()}
                </ContainerElement>
            )
        }
        return (
            <ContainerElement onMouseLeave={this.onClose} data-test={this.props.dataTest}>
                <ScreenSwitcher
                  other={this.renderNative}
                  medium={this.renderDropdown}
                />
            </ContainerElement>
        )
    }
}

export default Dropdown
