import styled from 'styled-components'

const ScrollAnchor = styled.div`
    float: left;
    margin-top: -64px;
    margin-bottom: 1em;
    width: 100%;
`

export default ScrollAnchor
