import React from 'react'
import PropTypes from 'prop-types'
import { themeColor } from '../../util/themeLenses'
import styled from 'styled-components'
import { style } from 'styled-system'
import Copy from '../Copy'

export const fontSize = style({
  prop: 'fontSize',
  alias: 'size',
  key: 'font.sizes',
  cssProperty: 'fontSize',
})

export const fontWeight = style({
  prop: 'fontWeight',
  alias: 'weight',
  key: 'font.weights',
  cssProperty: 'fontWeight',
})

const errorColorValue = ({ errorColor, errors }) =>
  typeof errorColor === 'function'
    ? themeColor(errorColor(errors))
    : themeColor(errorColor)

export const Wrapper = ({ children }) => (
  <div>{React.Children.toArray(children)[0]}</div>
)

Wrapper.propTypes = {
  children: PropTypes.node,
}

export class Error extends React.PureComponent {
  render() {
    return <StyledError fontWeight="regular" size="xxSmall" {...this.props} />
  }
}

const StyledError = styled(Copy)`
  color: ${errorColorValue};
  ${fontSize};
  ${fontWeight};
  padding: 8px 0 0 0;
`
