import React from 'react'
import PropTypes from 'prop-types'
import Hide from '../Hide'
import DropdownMenuDesktop from './DropdownMenuDesktop'
import DropdownMenuMobile from './DropdownMenuMobile'

const DropdownMenu = ({ desktopMenu, mobileMenu, logo }) => (
    <>
        <Hide md lg>
            <DropdownMenuMobile mobileMenu={mobileMenu} logo={logo} />
        </Hide>
        <Hide xs sm>
            <DropdownMenuDesktop desktopMenu={desktopMenu} />
        </Hide>
    </>
)

DropdownMenu.propTypes = {
    mobileMenu: PropTypes.arrayOf(PropTypes.shape({
        active: PropTypes.bool,
        text: PropTypes.node,
        url: PropTypes.string,
    })),
    desktopMenu: PropTypes.shape({
        renderButtonValue: PropTypes.node.isRequired,
        subItems: PropTypes.arrayOf(PropTypes.shape({
            active: PropTypes.bool,
            text: PropTypes.node,
            url: PropTypes.string,
        })),
    }),
    logo: PropTypes.node
}

export default DropdownMenu
