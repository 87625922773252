import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
    marginBottom,
    marginTop,
    marginRight,
    marginLeft,
    display,
    width,
    letterSpacing,
    color,
    align,
    lines,
    boxOrient,
    fontSize,
    fontWeight,
    lineHeight,
    lineHeightCorresponsive,
    transform,
    decoration,
    overflow,
    padding,
    wordBreak,
    alignSelf,
} from '../../util/styles'
import {
    themeFontFamily,
} from '../../util/themeLenses'
import { FONT_SIZES, FONT_WEIGHTS, COLORS, LINE_HEIGHT } from '../../util/theme'

export { FONT_SIZES, FONT_WEIGHTS, COLORS, LINE_HEIGHT }

const Copy = styled.div`
    ${lines}
    ${boxOrient}
    ${overflow}
    ${marginBottom}
    ${marginTop}
    ${marginRight}
    ${marginLeft}
    ${letterSpacing}
    ${display}
    ${color}
    font-family: '${themeFontFamily()}';
    ${align}
    ${fontSize}
    ${lineHeightCorresponsive}
    ${lineHeight}
    ${fontWeight}
    ${padding}
    ${width}
    ${transform}
    ${decoration}
    ${alignSelf}
    ${wordBreak}
`

Copy.propTypes = {
    fontWeight: PropTypes.oneOfType([
        PropTypes.oneOf(FONT_WEIGHTS),
        PropTypes.arrayOf(PropTypes.oneOf(FONT_WEIGHTS)),
    ]),
    size: PropTypes.oneOfType([
        PropTypes.oneOf(FONT_SIZES),
        PropTypes.arrayOf(PropTypes.oneOf(FONT_SIZES)),
    ]),
    color: PropTypes.oneOfType([
        PropTypes.oneOf(['inherit', ...COLORS]),
        PropTypes.arrayOf(PropTypes.oneOf(COLORS)),
    ]),
    align: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    lineHeight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf(FONT_SIZES),
        PropTypes.arrayOf(PropTypes.oneOf(FONT_SIZES)),
    ]),
    padding: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    transform: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    decoration: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
}

Copy.defaultProps = {
    color: 'gray',
    size: 'xxSmall',
    fontWeight: 'medium',
    padding: '10px 0 5px 0',
}

Copy.span = styled(Copy).attrs({
    display: 'inline-block',
})``

Copy.truncate = styled(Copy).attrs()`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

Copy.strong = styled(Copy).attrs({
    padding: '0',
    display: 'inline-block',
    fontWeight: 'book',
})``

Copy.clamp = styled(Copy).attrs({
    display: '-webkit-box',
    overflow: 'hidden',
    boxOrient: 'vertical',
})``

export default Copy
