import styled from 'styled-components'
import {
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  minWidth,
} from 'styled-system'

import Box from '../Box'

const Flex = styled(Box)`
    display: flex;
    ${flexWrap}
    ${flexDirection}
    ${alignItems}
    ${justifyContent}
    ${minWidth}
`

Flex.propTypes = {
  ...flexWrap.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
}

export default Flex
