export const theme = {
  colors: {
    main: '#007787',
    main80: '#33929F',
    main60: '#66ADB7',
    main40: '#99C8CF',
    main20: '#CCE3E7',

    darkMain: '#02363C',
    darkMain80: '#325B60',
    darkMain60: '#5F8A90',
    darkMain40: '#90B3B8',
    darkMain20: '#EBEFF0',

    accent: '#A9F4D4',
    accent80: '#B7F6DC',
    accent60: '#CBF7E5',
    accent40: '#DCF8EC',
    accent20: '#F2FAF6',

    gray: '#555759',
    gray80: '#77787A',
    gray60: '#999A9B',
    gray40: '#BBBCBD',
    gray20: '#DDDDDE',

    lightGray: '#AEAEAE',
    lightGray80: '#BBBBBB',
    lightGray60: '#CECFD0',
    lightGray40: '#E2E3E3',
    lightGray20: '#F7F7F7',

    warning: '#F6D423',
    warning80: '#F7E58F',
    warning60: '#F7EAAB',
    warning40: '#F8F1C9',
    warning20: '#FDFAEA',

    error: '#CF2E45',
    error80: '#D65567',
    error60: '#DD7D8A',
    error40: '#E4A3AD',
    error20: '#EBCBD0',

    success: '#8BD8AD',
    success80: '#9FDDBA',
    success60: '#B5E3C9',
    success40: '#C9E8D6',
    success20: '#DEEDE5',

    blue: '#00A8F3',
    white: '#FFFFFF',
    black: '#000000',
    green: '#61932E',
    red: '#D0021B',
    googleRed: '#f44336',
    orange: '#EC6806',
    facebookBlue: '#405b8c',
    darkFacebookBlue: '#355089',
    violetPlus: '#54127D',
    transparent: 'transparent',
  },
  decoration: {
    radius: '2px',
  },
  screens: {
    xSmall: '370px',
    small: '600px',
    medium: '768px',
    xLarge: '980px',
    xxLarge: '1025px',
    xxxLarge: '1200px',
  },
  screensMinMax: {
    xs: { max: '370px' },
    sm: { min: '371px', max: '600px' },
    md: { min: '601px', max: '768px' },
    lg: { min: '769px' },
  },
  font: {
    weights: {
      extralight: 100,
      light: 200,
      regular: 300,
      medium: 400, // Book
      book: 500, // Medium
      bold: 700,
      black: 800,
    },
    sizes: {
      xxxxLarge: '44px',
      xxxLarge: '36px',
      xxLarge: '24px',
      xLarge: '20px',
      large: '18px',
      medium: '16px',
      small: '14px',
      xSmall: '13px',
      xxSmall: '12px',
      xxxSmall: '10px',
    },
    lineHeight: {
      xxxxLarge: '1.2em',
      xxxLarge: '1.2em',
      xxLarge: '1.2em',
      xLarge: '1.2em',
      large: '1.2em',
      medium: '1.3em',
      small: '1.4em',
      xSmall: '1.4em',
      xxSmall: '1.4em',
      xxxSmall: '1.4em',
    },
  },
  gutter: 0,
  fontFamily: 'geomanist',
}

export const LINE_HEIGHT = Object.keys(theme.font.lineHeight)
export const FONT_SIZES = Object.keys(theme.font.sizes)
export const FONT_WEIGHTS = Object.keys(theme.font.weights)
export const COLORS = Object.keys(theme.colors)

export function createTheme({
  colors = {},
  decoration = {},
  screens = {},
  screensMinMax = {},
  font = {},
  gutter = theme.fontFamily,
  breakpoints = [
    theme.screens.small,
    theme.screens.medium,
    theme.screens.xLarge,
  ],
  fontFamily = theme.fontFamily,
} = {}) {
  return {
    colors: {
      ...theme.colors,
      ...colors,
    },
    decoration: {
      ...theme.decoration,
      ...decoration,
    },
    screens: {
      ...theme.screens,
      ...screens,
    },
    screensMinMax: {
      xs: {
        ...theme.screensMinMax.xs,
        ...screensMinMax.xs,
      },
      sm: {
        ...theme.screensMinMax.sm,
        ...screensMinMax.sm,
      },
      md: {
        ...theme.screensMinMax.md,
        ...screensMinMax.md,
      },
      lg: {
        ...theme.screensMinMax.lg,
        ...screensMinMax.lg,
      },
    },
    font: {
      weights: {
        ...theme.font.weights,
        ...font.weights,
      },
      sizes: {
        ...theme.font.sizes,
        ...font.sizes,
      },
      lineHeight: {
        ...theme.font.lineHeight,
        ...font.lineHeight,
      },
    },
    gutter,
    breakpoints,
    fontFamily,
  }
}
