import styled from 'styled-components'

import {
  display,
  alignContent,
  gridGap,
  gridColumnGap,
  gridRowGap,
  gridAutoFlow,
  gridAutoColumns,
  gridAutoRows,
  gridTemplateAreas,
  justifyContent,
  backgroundColor,
  space,
  width,
  zIndex,
  gridTemplateRows,
  msGridTemplateRows,
  gridTemplateColumns,
  msGridTemplateColumns
} from '../../util/styles'

const Grid = styled.div`
  ${display}
  ${backgroundColor}
  ${gridAutoFlow}
  ${gridAutoRows}
  ${gridAutoColumns}
  ${gridTemplateRows}
  ${msGridTemplateRows}
  ${gridTemplateColumns}
  ${msGridTemplateColumns}
  ${gridGap}
  ${gridRowGap}
  ${gridColumnGap}
  ${gridTemplateAreas}
  ${justifyContent}
  ${alignContent}
  ${width}
  ${space}
  ${zIndex}
`

Grid.defaultProps = {
  display : 'grid'
}

export default Grid
