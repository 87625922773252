import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const accentColor = (props) => props.theme.colors.accent || 'red'

const EnumerationItem = styled.li`
  padding: 1em 1em 1em 1em;
  margin-top: 1em;
  border-left: 1px solid ${accentColor};

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    border-radius: 50%;
    background: ${accentColor};
    height: 0.4em;
    width: 0.4em;
    margin-left: -1.2em;
    margin-top: -1.7em;
  }
  &:last-child {
    border-left: 1px solid transparent;
  }
`
const EnumerationItemContent = styled.div`
  margin-top: -2em;
`

const EnumerationContent = styled.ul`
  padding: 1em 0 0 0;
  position: relative;
`

const Enumeration = ({ items }) => (
  <EnumerationContent>
    {items.map((item, index) => (
      <EnumerationItem key={`enumeration_${index}`}>
        <EnumerationItemContent>{item}</EnumerationItemContent>
      </EnumerationItem>
    ))}
  </EnumerationContent>
)

Enumeration.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
}

Enumeration.defaultProps = {
  items: [],
}

export default Enumeration
