import firebase from 'firebase/app'
import 'firebase/remote-config'

import defaultConfig from './defaultConfig'

let remoteConfigInstance

export function getRemoteConfig ({
    apiKey,
    authDomain,
    databaseURL,
    projectId,
    storageBucket,
    messagingSenderId,
    appId
}) {
    if (firebase.apps.length === 0) {
        firebase.initializeApp({
            apiKey,
            authDomain,
            databaseURL,
            projectId,
            storageBucket,
            messagingSenderId,
            appId
        })
    }
    if (!remoteConfigInstance) {
        remoteConfigInstance = firebase.remoteConfig()
        remoteConfigInstance.defaultConfig = defaultConfig
        remoteConfigInstance.settings = {
            minimumFetchIntervalMillis: 10800000,
        }
    }

    return remoteConfigInstance
}
