import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Icon from '../Icon'

const IconButtonWrapper = ({ type, size, color, ...restProps }) => (
    <Button.unstyled {...restProps}>
        <Icon type={type} size={size} color={color} />
    </Button.unstyled>
)

IconButtonWrapper.propTypes = {
    type: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
}

export default IconButtonWrapper
