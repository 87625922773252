import React from 'react'
import PropTypes from 'prop-types'
import { ConfigProvider } from '../EPConfig'
import { ThemeProvider } from 'styled-components'

const EPProvider = ({ children, theme, ...config }) => (
    <ThemeProvider theme={theme}>
        <ConfigProvider {...config}>
            {children}
        </ConfigProvider>
    </ThemeProvider>
)

EPProvider.propTypes = {
    children: PropTypes.node.isRequired,
    theme: PropTypes.object.isRequired,
}

export default EPProvider