/* istanbul ignore file */
import { logger } from '@elparking/utils'

const SCRIPT_ELEMENT_ID = 'scriptGoogleReCaptchaV3'

const renderRecaptchaScript = (siteKey) => {
    if (!document.getElementById(SCRIPT_ELEMENT_ID)) {
        try {
          const script = document.createElement('script')
          script.src = `https://www.recaptcha.net/recaptcha/api.js?&render=${siteKey}`
          script.id = SCRIPT_ELEMENT_ID
          document.body.appendChild(script)
        } catch (e) {
        logger.error('Error loading Recaptcha')
      }
    }
}

const isReady = () =>
  typeof window !== 'undefined' &&
  typeof window.grecaptcha !== 'undefined' &&
  typeof window.grecaptcha.execute !== 'undefined'

const execute = ({
    ready,
    sitekey,
    action,
    callback,
  }) => {
    if (ready) {
      try {
        window.grecaptcha.execute(sitekey, { action })
          .then(token => {
            callback(token)
          })
          .catch (() => {
            logger.error('Error executing Recaptcha execute')
        })
      } catch (e) {
        logger.error('Error executing Recaptcha')
      }
    }
  }

export {
    renderRecaptchaScript,
    isReady,
    execute,
}
