import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../EPModal'
import { FormattedMessage } from 'react-intl'
import GenericErrorModalContent from './GenericErrorModalContent'

class GenericErrorModal extends React.PureComponent {
    static propTypes = {
        title: PropTypes.node,
        message: PropTypes.node,
        buttonLabel: PropTypes.node,
        onClick: PropTypes.func,
    }

    static defaultProps = {
        title: (
            <FormattedMessage
                defaultMessage='Error'
            />
        ),
        message: (
            <FormattedMessage
                defaultMessage='No hemos podido contactar con el servidor.'
            />
        ),
        buttonLabel: (
            <FormattedMessage
                defaultMessage='Aceptar'
            />
        ),
    }

    render() {
        const { title, ...restProps } = this.props
        return (
            <Modal isShown title={title} label='GenericError' showCloseButton={false} width='500px'>
                <GenericErrorModalContent {...restProps} />
            </Modal>
        )
    }
}

export default GenericErrorModal
