/* eslint-disable no-console */
/* istanbul ignore file */
export function wrapLog (log) {
    return function () {
        if (DEBUG) {
            log.apply(null, Array.prototype.slice.apply(arguments))
        }
    }
}

export const log = wrapLog(console.log)
export const info = wrapLog(console.info)
export const warn = wrapLog(console.warn)
export const error = wrapLog(console.error)
