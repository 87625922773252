import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  themeFontSize,
  themeFontWeight,
  themeColor,
} from '../../util/themeLenses'

const DEFAULT_LABEL_FONT_SIZE = '9px'
const DEFAULT_LABEL_HEIGHT = '12px'
const DEFAULT_LABEL_LINE_HEIGHT = '10px'
const DEFAULT_LABEL_FONT_WEIGHT = 'medium'
const DEFAULT_MOBILE_HEIGHT = '44px'

const mediaScreenMobile = props => `@media screen and (max-width: ${props.theme.screens.medium})`

const inputLabelColorMobile = ({ active, focus }) => {
    if (active) {
        if (focus) {
            return themeColor('main')
        }
        return themeColor('gray')
    }
    return themeColor('gray60')
}

const fontSizeLabelSizeMobile = ({ active, fontSize }) => active ? DEFAULT_LABEL_FONT_SIZE : themeFontSize(fontSize)
const fontWeightLabelSizeMobile = ({ active }) => active ? themeFontWeight(DEFAULT_LABEL_FONT_WEIGHT) : themeFontWeight('regular')

const inputLabelMobilePaddingTop = ({ active }) => active ? '6px' : '0'
const inputLabelMobilePaddingLeft = ({ active, leftIcon, paddingWithIcon, padding }) => {
    if (active) {
        return padding
    }
    return leftIcon ? paddingWithIcon : padding
}

const inputLabelMobileLineHeight = ({ active }) => active ? 'auto' : DEFAULT_MOBILE_HEIGHT
const inputLabelMobileTransform = ({ active }) => active ? 'uppercase' : 'none'
const inputLabelDisplay = ({ hideInDesktop }) => hideInDesktop ? 'none' : 'block'
const inputLabelMobileDisplay = ({ leftIcon }) => leftIcon ? 'none' : 'block'
const inputPaddingWithIcon = ({ paddingWithIcon }) => paddingWithIcon

export const Label = styled.label`
  display: ${inputLabelDisplay};
  font-weight: ${themeFontWeight('medium')};
  font-size: ${DEFAULT_LABEL_FONT_SIZE};
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 4px;
  white-space: nowrap;
  color: ${themeColor('gray')};
  width: 100%;
  margin: 0;
  height: ${DEFAULT_LABEL_HEIGHT};
  line-height: ${DEFAULT_LABEL_LINE_HEIGHT};
  ${mediaScreenMobile} {
      top: 2px;
      display: ${inputLabelMobileDisplay};
      transition: padding-top 0.5s;
      padding-top: ${inputLabelMobilePaddingTop};
      padding-left: ${inputLabelMobilePaddingLeft};
      padding-right: ${inputPaddingWithIcon};
      text-transform: ${inputLabelMobileTransform};
      color: ${inputLabelColorMobile};
      font-size: ${fontSizeLabelSizeMobile};
      font-weight: ${fontWeightLabelSizeMobile};
      position: absolute;
      height: ${DEFAULT_MOBILE_HEIGHT};
      line-height: ${inputLabelMobileLineHeight};
      z-index: 1;
      pointer-events: none;
  }
`

Label.propTypes = {
  focus: PropTypes.bool,
  active: PropTypes.bool,
  hideInDesktop: PropTypes.bool,
  width: PropTypes.string,
  fontSize: PropTypes.string,
  leftIcon: PropTypes.any,
  padding: PropTypes.string,
  paddingWithIcon: PropTypes.string,
}

export default Label
