import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { themeFontFamily, themeColor } from '../../util/themeLenses'
import { FONT_SIZES, FONT_WEIGHTS, COLORS } from '../../util/theme'

import {
    marginBottom,
    marginTop,
    marginRight,
    marginLeft,
    display,
    width,
    color,
    height,
    fontSize,
    fontWeight,
    lineHeight,
    transform,
    overflow,
    padding,
    borderRadius,
    backgroundColor,
    borderStyle,
    borderWidth,
    borderColor
} from '../../util/styles'

const shadow = props => props.withShadow ? '0px 3px 5px 0px rgba(0, 0, 0, 0.2)' : 'none'
const disabledBackgroundColor = ({disabledBackgroundColor}) => themeColor(disabledBackgroundColor)
const hoverBorderColor = ({hoverBorderColor}) => themeColor(hoverBorderColor)
const hoverColor = ({hoverColor}) => themeColor(hoverColor)
const hoverBackgroundColor = ({hoverBackgroundColor}) => themeColor(hoverBackgroundColor)
const activeBackgroundColor = ({activeBackgroundColor}) => themeColor(activeBackgroundColor)

const styles = css`
    cursor: pointer;
    outline: none;
    ${overflow}
    ${display}
    ${transform}
    ${marginBottom}
    ${marginTop}
    ${marginRight}
    ${marginLeft}
    ${borderRadius}
    ${borderStyle}
    ${borderWidth}
    ${borderColor}
    ${fontWeight}
    ${fontSize}
    ${color}
    ${height}
    ${lineHeight}
    ${padding}
    ${width}
    ${backgroundColor}

    box-shadow: ${shadow};
    font-family: '${themeFontFamily()}';
    &:active {
        background-color: ${activeBackgroundColor};
    }

    &:hover {
        background-color: ${hoverBackgroundColor};
        border-color: ${hoverBorderColor};
        color: ${hoverColor};
    }

    &:disabled {
        background-color: ${disabledBackgroundColor};
        cursor: not-allowed;
    }
`

export { FONT_SIZES, FONT_WEIGHTS, COLORS }

const Button = styled.button`${styles}`
Button.propTypes = {
    fontWeight: PropTypes.oneOfType([
        PropTypes.oneOf(FONT_WEIGHTS),
        PropTypes.arrayOf(PropTypes.oneOf(FONT_WEIGHTS)),
    ]),
    fontSize: PropTypes.oneOfType([
        PropTypes.oneOf(FONT_SIZES),
        PropTypes.arrayOf(PropTypes.oneOf(FONT_SIZES)),
    ]),
    color: PropTypes.oneOfType([
        PropTypes.oneOf(['inherit', ...COLORS]),
        PropTypes.arrayOf(PropTypes.oneOf(COLORS)),
    ]),
    borderRadius: PropTypes.string,
    borderStyle: PropTypes.string,
    withShadow: PropTypes.bool,
    disabledBackgroundColor: PropTypes.oneOf(['inherit', ...COLORS]),
    hoverBackgroundColor: PropTypes.oneOf(['inherit', ...COLORS]),
    hoverColor: PropTypes.oneOf(['inherit', ...COLORS]),
    activeColor: PropTypes.oneOf(['inherit', ...COLORS]),
    backgroundColor: PropTypes.oneOf(['inherit', ...COLORS]),
    borderWidth: PropTypes.string,
    borderColor: PropTypes.oneOf(['inherit', ...COLORS]),
}

Button.defaultProps = {
    borderStyle: 'solid',
    shadow: 'none',
    display: 'inline-block',
    backgroundColor: 'main',
    fontSize: 'small',
    fontWeight: 'medium',
    width: 'auto',
    height: '40px',
    padding: '8px 20px',
    disabledBackgroundColor: 'gray20',
    hoverBackgroundColor: 'darkMain',
    hoverColor: 'white',
    activeBackgroundColor: 'gray',
    color: 'white',
    borderWidth: '0',
    borderColor: 'transparent',
    hoverBorderColor: 'transparent',
    borderRadius: '2px',
}

Button.bordered = styled(Button)``

Button.bordered.defaultProps = {
    borderWidth: '1px',
    borderColor: 'main',
    backgroundColor: 'white',
    color: 'main',
}

Button.unstyled = styled(Button)``

Button.unstyled.defaultProps = {
    backgroundColor: 'transparent',
    disabledBackgroundColor: 'transparent',
    hoverBackgroundColor: 'transparent',
    fontSize: 'small',
    fontWeight: 'medium',
    color: 'main',
    hoverColor: 'main',
    padding: '0',
    height: 'auto',
}

export default Button

