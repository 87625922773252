import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import {
  FieldContainer,
  FieldInputContainer,
  IconContainer,
  IconRightContainer,
  IconRightContainerMobile,
  FieldInputStylized,
  Label,
} from './layout'

const DEFAULT_HEIGHT = '36px'
const DEFAULT_TEXT_COLOR = 'gray'
const DEFAULT_FONT_WEIGHT = 'regular'
const DEFAULT_FONT_SIZE = 'small'
const DEFAULT_ERROR_COLOR = 'red'
const DEFAULT_VALID_COLOR = 'green'
const DEFAULT_FIELD_WIDTH = '100%'
const DEFAULT_BACKGROUND_COLOR = 'white'
const DEFAULT_PADDING_WITH_ICON = '32px'
const DEFAULT_PADDING = '8px'

class Input extends React.Component {
    static propTypes = {
        valid: PropTypes.bool,
        focus: PropTypes.bool,
        changed: PropTypes.bool,
        rightIcon: PropTypes.any,
        leftIcon: PropTypes.any,
        validateIcon: PropTypes.bool,
        fontColor: PropTypes.string,
        fontWeight: PropTypes.string,
        textAlign: PropTypes.string,
        fontSize: PropTypes.string,
        height: PropTypes.string,
        errorColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        validColor: PropTypes.string,
        errorIcon: PropTypes.string,
        validIcon: PropTypes.string,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        width: PropTypes.string,
        backgroundColor: PropTypes.string,
        onChange: PropTypes.func,
        getFieldValue: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        value: PropTypes.string,
        inputClassName: PropTypes.string,
        fieldInputContainerComponent: PropTypes.elementType,
        inputComponent: PropTypes.elementType,
        showClearButton: PropTypes.bool,
        errors: PropTypes.object,
        forceFocus: PropTypes.bool,
        padding: PropTypes.string,
        type: PropTypes.string,
        paddingWithIcon: PropTypes.string,
    }

    static defaultProps = {
        showClearButton: true,
        valid: true,
        focus: false,
        changed: false,
        rightIcon: null,
        leftIcon: null,
        validateIcon: false,
        fontColor: DEFAULT_TEXT_COLOR,
        fontWeight: DEFAULT_FONT_WEIGHT,
        fontSize: DEFAULT_FONT_SIZE,
        height: DEFAULT_HEIGHT,
        errorColor: DEFAULT_ERROR_COLOR,
        validColor: DEFAULT_VALID_COLOR,
        width: DEFAULT_FIELD_WIDTH,
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        padding: DEFAULT_PADDING,
        paddingWithIcon: DEFAULT_PADDING_WITH_ICON,
        onChange: null,
        label: null,
        placeholder: null,
        inputClassName: null,
        onBlur: () => {},
        onFocus: () => {},
        getFieldValue: ({ value }) => value,
        fieldInputContainerComponent: FieldInputContainer,
        inputComponent: FieldInputStylized,
        errors: {},
        type: 'text',
        textAlign: 'left',
        forceFocus: false,
    }

    onDeleteField () {
        this.focus()
        this.props.onChange('')
    }

    onDeleteField = this.onDeleteField.bind(this)

    focus () {
        this.inputRef && this.inputRef.focus()
    }

    focus = this.focus.bind(this)

    onChange ({target}) {
        const { onChange, getFieldValue } = this.props
        onChange && target && onChange(getFieldValue(target))
    }

    onChange = this.onChange.bind(this)

    onFocus () {
        this.setState(
            () => ({focus: true}),
            () => { this.props.onFocus() }
        )
    }

    onFocus = this.onFocus.bind(this)

    onBlur () {
        this.setState(
            () => ({focus: false}),
            () => { this.props.onBlur() }
        )
    }

    onBlur = this.onBlur.bind(this)

    state = {
        focus: false,
    }

    componentDidUpdate ({ forceFocus }) {
        if (!forceFocus && this.props.forceFocus) {
            this.focus()
            this.props.onFocus()
        }
    }

    componentDidMount () {
        const { forceFocus, onFocus } = this.props
        if (forceFocus) {
            this.focus()
            onFocus()
        }
    }

    renderLeftIcon () {
        const { leftIcon, height, backgroundColor, padding, paddingWithIcon } = this.props
        if (!leftIcon) {
            return null
        }
        return (<IconContainer
          active
          paddingWithIcon={paddingWithIcon}
          padding={padding}
          backgroundColor={backgroundColor}
          onClick={this.focus}
          height={height}
                >{leftIcon}
        </IconContainer>)
    }

    renderRightIcon (errorColorValue) {
        const { focus } = this.state
        const { validateIcon, valid, height, changed, backgroundColor, padding, paddingWithIcon } = this.props
        if (validateIcon && changed && !focus) {
            if (!valid) {
                return (
                    <IconRightContainer
                      paddingWithIcon={paddingWithIcon}
                      padding={padding}
                      backgroundColor={backgroundColor}
                      onClick={this.focus}
                      height={height}
                    >
                        <Icon type='exclamation' color={errorColorValue} />
                    </IconRightContainer>
                )
            } else {
                return (
                    <IconRightContainer
                      backgroundColor={backgroundColor}
                      paddingWithIcon={paddingWithIcon}
                      padding={padding}
                      onClick={this.focus}
                      height={height}
                    >
                        <Icon type='ok' color='green' />
                    </IconRightContainer>
                )
            }
        }

        const { rightIcon } = this.props
        if (!rightIcon) {
            return null
        }
        return (<IconRightContainer
          paddingWithIcon={paddingWithIcon}
          padding={padding}
          backgroundColor={backgroundColor}
          onClick={this.focus}
          height={height}
                >{rightIcon}
        </IconRightContainer>)
    }

    render () {
        const {
            changed,
            fieldInputContainerComponent: FieldInputContainerComponent,
            inputComponent: InputComponent,
            valid,
            focus: propsFocus,
            inputClassName,
            leftIcon,
            rightIcon,
            validateIcon,
            height,
            errorColor,
            validColor,
            errorIcon,
            validIcon,
            label,
            width,
            backgroundColor,
            fontSize,
            placeholder,
            onFocus,
            onBlur,
            errors,
            type,
            textAlign,
            padding,
            paddingWithIcon,
            showClearButton,
            ...restProps } = this.props
        const { focus: stateFocus } = this.state
        const { value } = this.props
        const focus = propsFocus || stateFocus
        const active = !!(focus || value)
        const showLabel = !!(label || placeholder)
        const errorColorValue = typeof errorColor === 'function' ? errorColor(errors) : errorColor
        const iconMobileColor = (!valid && changed && !focus) ? errorColorValue : 'gray60'
        return (
            <FieldContainer width={width} padding={padding} paddingWithIcon={paddingWithIcon}>
                {showLabel && <Label
                  paddingWithIcon={paddingWithIcon}
                  padding={padding}
                  width={width}
                  hideInDesktop={!!(placeholder && !label)}
                  leftIcon={leftIcon}
                  active={active}
                  focus={focus}
                  fontSize={fontSize}
                              >{label || placeholder}
                </Label>}
                <FieldInputContainerComponent
                  errorColor={errorColorValue}
                  focus={focus}
                  valid={valid}
                  backgroundColor={backgroundColor}
                >
                    {this.renderLeftIcon()}
                    <InputComponent
                      {...restProps}
                      ref={(ref) => (this.inputRef = ref)}
                      leftIcon={leftIcon}
                      className={inputClassName}
                      focus={focus}
                      valid={valid}
                      height={height}
                      type={type}
                      value={type === 'file' ? undefined : value || ''}
                      padding={padding}
                      placeholder={placeholder}
                      onChange={this.onChange}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      fontSize={fontSize}
                      textAlign={textAlign}
                      errorColor={errorColorValue}
                    />
                    {showClearButton && !!value &&
                    <IconRightContainerMobile
                      paddingWithIcon={paddingWithIcon}
                      padding={padding}
                      onClick={this.onDeleteField}
                      active={active}
                      backgroundColor={backgroundColor}
                      height={height}
                    >
                        <Icon color={iconMobileColor} type='x-rounded' />
                    </IconRightContainerMobile>}
                    {this.renderRightIcon(errorColorValue)}
                </FieldInputContainerComponent>
            </FieldContainer>
        )
    }
}

export default Input
