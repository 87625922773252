import React, { useState, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import Input from '../Input'
import Icon from '../Icon'
import { Error as FieldError }  from '../Error'
import { defineMessages, injectIntl } from 'react-intl'

const messages = defineMessages({
    label: {
        defaultMessage: 'Kilometraje',
    },
    placeholder: {
        defaultMessage: 'Ej.: {value}',
    },
    inputError: {
        defaultMessage: 'El número de kilómetros debe estar entre 0 y {value}',
    },
})

const getSeparator = (locale, separatorType) => {
    const numberWithGroupAndDecimalSeparator = 10000.1
    return Intl.NumberFormat(locale)
        .formatToParts(numberWithGroupAndDecimalSeparator)
        .find(part => part.type === separatorType)
        .value
}

const MAX_KILOMETERS = 2000000

const WorkshopBookingKmInput = ({
    updateKm,
    intl,
    km,
}) => {
    const [kilometers, setKilometers] = useState('')
    const [error, setError] = useState(null)
    const [cursorPosition, setCursorPosition] = useState(null)

    var formatter = new Intl.NumberFormat(intl.locale)

    const kmInputRef = useRef(null)

    const memoizedSeparator = useMemo(() => getSeparator(intl.locale, 'group'), [intl])

    useEffect(() => {
        km > 0
            ? setKilometers(formatter.format(km))
            : setKilometers('')
    }, [km, formatter, updateKm])

    useEffect(() => {
        if (cursorPosition) {
            kmInputRef.current.inputRef.focus()
            kmInputRef.current.inputRef.setSelectionRange(cursorPosition, cursorPosition)
        }
    }, [kilometers, km, kmInputRef, cursorPosition, error])

    const maskKilometers = (value) => {
        let maskKilometersError = null
        const regex = new RegExp('\\' + memoizedSeparator, 'g')
        const valueWitoutSeparator = value.replace(regex, '')
        const parsedValue = valueWitoutSeparator ? parseFloat(valueWitoutSeparator) : 0

        let gapParsedValue = 0
        if (kilometers.length === formatter.format(parsedValue).length || kmInputRef.current.inputRef.selectionStart < 2) {
            gapParsedValue = 0
        } else if (kmInputRef.current.inputRef.selectionStart === 2 && formatter.format(parsedValue).length - value.length < 0) {
            gapParsedValue = -1
        } else {
            gapParsedValue = formatter.format(parsedValue).length - value.length
        }

        setCursorPosition(kmInputRef.current.inputRef.selectionStart + (gapParsedValue))
        if (!isNaN(parsedValue)) {
            if (parsedValue > 0 && parsedValue < MAX_KILOMETERS) {
                maskKilometersError = null
            } else {
                maskKilometersError = intl.formatMessage(messages.inputError, { value: formatter.format(MAX_KILOMETERS) })
            }
            setError(maskKilometersError)
            updateKm({
                value: parsedValue,
                error: maskKilometersError,
            })
        }
    }
    return (
        <>
            <Input
              ref={kmInputRef}
              data-test='workshop-booking-km-input-field'
              valid={!error}
              fontSize='medium'
              rightIcon={error ? <Icon color='error' type='x' /> : null}
              label={intl.formatMessage(messages.label)}
              placeholder={intl.formatMessage(messages.placeholder, { value: formatter.format(150000) })}
              onChange={maskKilometers}
              value={kilometers}
            />
            {error && <FieldError FieldErrorColor='red'>{error}</FieldError>}
        </>
    )
}

WorkshopBookingKmInput.propTypes = {
    updateKm: PropTypes.func,
    km: PropTypes.number,
    intl: PropTypes.object,
}

export default injectIntl(WorkshopBookingKmInput)
