/* eslint-disable no-console */
function wrapLog(log) {
  return function(...args) {
    if (window.DEBUG) {
      log(...args)
    }
  }
}

module.exports.log = wrapLog(console.log)
module.exports.info = wrapLog(console.info)
module.exports.warn = wrapLog(console.warn)
module.exports.error = wrapLog(console.error)
