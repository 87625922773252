const DEFAULT_DIGITS = 2
const DEFAULT_FORMAT = '{sign}{number} {currency}'
const DEFAULT_SYMBOL = '€'

function getCurrencyConfig (currency, { currencyFormats } = {}) {
    const config = (currencyFormats || []).find(item => item.code === currency)
    return config || null
}

export function getFractionDigits (currency, epConfig) {
    const config = getCurrencyConfig(currency, epConfig)
    return config ? config.fractionDigits : DEFAULT_DIGITS
}

export function getCurrencyFormat (currency, epConfig) {
    const config = getCurrencyConfig(currency, epConfig)
    return config ? config.format : DEFAULT_FORMAT
}

export function getCurrencySymbol (currency, epConfig) {
    const config = getCurrencyConfig(currency, epConfig)
    return config ? config.symbol : DEFAULT_SYMBOL
}
