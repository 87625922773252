import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import toCurrency from './toCurrency'
import { ConfigContext } from '../../providers/EPConfig'

const CurrencyNumber = ({ qty, intl, formatOptions = null }) => {
    const epConfig = React.useContext(ConfigContext)
    if (!epConfig) {
        throw new Error('Config provider has not been loaded')
    }
    return <span>{toCurrency(
        intl,
        qty,
        formatOptions,
        epConfig)
    }</span>
}

CurrencyNumber.propTypes = {
    qty: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
    }),
    intl: PropTypes.object,
    formatOptions: PropTypes.object,
}

CurrencyNumber.defaultProps = {
    amountWrapper: null,
    currencyWrapper: null,
    formatOptions: {},
}


export default injectIntl(CurrencyNumber)
