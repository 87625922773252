import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'

import RenderSwitch from '../RenderSwitch'
import { themeMediaQuery } from '../../util/themeLenses'

const xSmallMediaQuery = themeMediaQuery('xSmall')
const smallMediaQuery = themeMediaQuery('small')
const mediumMediaQuery = themeMediaQuery('medium')
const xLargeMediaQuery = themeMediaQuery('xLarge')
const xxLargeMediaQuery = themeMediaQuery('xxLarge')
const xxxLargeMediaQuery = themeMediaQuery('xxxLarge')

const matchesMedia = (query) => {
    const [normalizedQuery] = query.match(/\((.+\))/g)
    if (typeof window !== 'undefined') {
        return window.matchMedia(normalizedQuery).matches
    }
    return false
}

function ScreenSwitch ({ xSmall, small, medium, xLarge, xxLarge, xxxLarge, other, ...restProps }) {
    return (
        <RenderSwitch
          {...restProps}
          xSmall={xSmall}
          small={small}
          medium={medium}
          xLarge={xLarge}
          xxLarge={xxLarge}
          xxxLarge={xxxLarge}
          defaultComponent={other}
          test={() => ({
              // NOTE: Order here is important. Should be from lower resolution to highest
              xSmall: matchesMedia(xSmallMediaQuery(restProps)),
              small: matchesMedia(smallMediaQuery(restProps)),
              medium: matchesMedia(mediumMediaQuery(restProps)),
              xLarge: matchesMedia(xLargeMediaQuery(restProps)),
              xxLarge: matchesMedia(xxLargeMediaQuery(restProps)),
              xxxLarge: matchesMedia(xxxLargeMediaQuery(restProps)),
          })}
        />
    )
}

ScreenSwitch.propTypes = {
    theme: PropTypes.object,
    other: PropTypes.func,
    xSmall: PropTypes.func,
    small: PropTypes.func,
    medium: PropTypes.func,
    xLarge: PropTypes.func,
    xxLarge: PropTypes.func,
    xxxLarge: PropTypes.func,
}

export default withTheme(ScreenSwitch)
