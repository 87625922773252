/* istanbul ignore file */

import { getConstant } from './constants'
import momentTZ from 'moment-timezone'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/ca'
import 'moment/locale/eu'
import 'moment/locale/fr'
import 'moment/locale/en-gb'
import 'moment/locale/pt-br'
import 'moment/locale/pl'
import {messages} from './messages'

export const defaultLocale = 'es'
const langDetected = Object.keys(messages).includes(getConstant('LANGUAGE'))
export const currentLocale = langDetected ? getConstant('LANGUAGE') : defaultLocale

function getMomentLocale (locale) {
    const localeMap = {
        'en-US': 'en',
        'en': 'en-gb',
        'es': 'es',
        'es-CR': 'es',
        'fr': 'fr',
        'eu': 'eu',
        'ca': 'ca',
        'pl': 'pl',
        'pt-BR': 'pt-br',
    }

    return localeMap[locale]
}

moment.updateLocale('es', {
    longDateFormat: {
        LT: 'HH:mm',
    },
})

const localeMoment = getMomentLocale(currentLocale)
moment.locale(localeMoment)
momentTZ.defineLocale(localeMoment, null)
momentTZ.updateLocale(localeMoment, moment.localeData()._config)

export default currentLocale
