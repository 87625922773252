/* istanbul ignore file */
export const themeFontWeight = (weight) => (props) =>
  props.theme.font ? props.theme.font.weights[weight] : 'inherit'
export const themeFontSize =
  (size, defaultSize = 'inherit') =>
  (props) =>
    props.theme ? props.theme.font.sizes[size] : defaultSize
export const themeColor = (color) => (props) =>
  props.theme && props.theme.colors ? props.theme.colors[color] : 'inherit'
export const themeBorderRadius = () => (props) =>
  props.theme ? props.theme.decoration.radius : 'inherit'
export const themeFontFamily = () => (props) =>
  props.theme ? props.theme.fontFamily : 'Arial'
export const themeMediaQuery = (screenSize) => (props) =>
  `@media screen and (min-width: ${props.theme.screens[screenSize]})`
export const themeMediaQueryMinMax = (key) => (props) => {
  const { min, max } = props.theme.screensMinMax[key]

  let query = '@media screen'

  if (min) {
    query = `${query} and (min-width: ${min})`
  }

  if (max) {
    query = `${query} and (max-width: ${max})`
  }

  return query
}
