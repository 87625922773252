import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Modal from 'react-modal'
import IconButton from '../IconButton'

import { themeFontSize, themeFontWeight, themeColor, themeMediaQuery } from '../../util/themeLenses'
import { padding } from '../../util/styles'
export const ModalContent = styled.div`
    background: ${themeColor('white')};
    color: ${themeColor('main')};
    ${padding};
    height: auto;
    width: auto;
    min-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: ${({ position }) => position};
    display: flex;
    flex-direction: column;

    ${themeMediaQuery('small')} {
        width: ${({ width }) => width};
        min-height: 0;
        margin: auto;
        top: 50px;
        left: auto;
        right: auto;
        bottom: auto;
    }
`

ModalContent.propTypes = {
    position: PropTypes.string,
    padding: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.string,
    height: PropTypes.string,
}

const ModalHeader = styled.div`
    display: flex;
    justify-content: flex-start;
`

const ModalTitle = styled.div`
    font-weight: ${themeFontWeight('medium')};
    font-size: ${themeFontSize('xxLarge')};

    margin-top: 8px;
    margin-bottom: 40px;
    margin-right: ${({showCloseButton}) => showCloseButton ? '24px' : '0'};
`

const ModalClose = styled.div`
    position: absolute;
    top: 14px;
    right: 0.5em;
`

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const customStyles = {
    content: {
        top: '0%',
        left: '0',
        right: '0',
        bottom: '0',
        marginRight: '0',
        padding: '0',
        border: 'none',
        background: 'transparent',
        minHeight: '100%',
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 9999,
    },
}

class EPModal extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        isShown: PropTypes.bool,
        label: PropTypes.string,
        title: PropTypes.node,
        children: PropTypes.node,
        width: PropTypes.string,
        position: PropTypes.string,
        padding: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.arrayOf(PropTypes.string)]),
        showCloseButton: PropTypes.bool,
        showModalTitle: PropTypes.bool,
    }

    static defaultProps = {
        onClose: () => {},
        width: 'auto',
        position: 'relative',
        padding: '20px 40px',
        showCloseButton: true,
        showModalTitle: true,
    }

    state = {
        error: null,
        isSubmitting: false,
        isOpen: this.props.isShown,
    }

    onClose = this.onClose.bind(this)
    onSubmit = this.onSubmit.bind(this)

    static getDerivedStateFromProps (nexProps, { isOpen }) {
        if (nexProps.isShown !== isOpen) {
            return {
                isOpen: nexProps.isShown,
            }
        }
        return null
    }

    onSubmit () {
        this.setState(() => ({
            error: null,
            isSubmitting: true,
        }))
    }

    onClose () {
        this.setState(() => ({
            isOpen: false,
        }), () => {
            this.props.onClose()
        })
    }

    render () {
        const { isOpen } = this.state
        const { width, showCloseButton, showModalTitle, padding, position } = this.props
        return (
            <Modal
              style={customStyles}
              contentLabel={this.props.label}
              onRequestClose={this.props.onClose}
              isOpen={isOpen}
              ariaHideApp={false}
              shouldCloseOnOverlayClick={false}
            >
                <ModalContent width={width} position={position} padding={padding}>
                    <ModalHeader>
                        {showModalTitle && <ModalTitle showCloseButton={showCloseButton}>{this.props.title}</ModalTitle>}
                        {showCloseButton && <ModalClose>
                            <IconButton id='modal-close-button' disabled={this.state.isSubmitting} padding='10px' type='x' size='xLarge' color='gray' onClick={this.onClose} />
                        </ModalClose>}
                    </ModalHeader>
                    <ModalBody>
                        {this.props.children}
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    }
}

export default EPModal
