import React from 'react'
import CurrencyNumber from './CurrencyNumber'
import styled from 'styled-components'
import { getCurrencyFormat, getCurrencySymbol } from './Config'
import PropTypes from 'prop-types'
import { ConfigContext } from '../../providers/EPConfig'
const CurrencyWrapper = styled.span` white-space: nowrap; `

const replacePatternToComponent = (text, pattern, replacements) => {
    const splitText = text.split(pattern)
    const matches = text.match(pattern)
    if (splitText.length <= 1) {
        return text
    }

    return splitText.reduce((arr, element) => {
        if (!element) return arr

        if (matches.includes(element)) {
            return [...arr, replacements[element]]
        }
        return [...arr, element]
    }, [])
}

const FormattedCurrency = ({ currency, amountElement, currencyElement, signElement }) => {
    const epConfig = React.useContext(ConfigContext)
    if (!epConfig) {
        throw new Error('Config provider has not been loaded')
    }
    const text = getCurrencyFormat(currency, epConfig)
    const pattern = /(\{sign\})|(\{number\})|(\{currency\})/g
    return replacePatternToComponent(text, pattern, { '{number}': amountElement, '{currency}': currencyElement, '{sign}': signElement })
}

FormattedCurrency.propTypes = {
    currency: PropTypes.string,
    amountElement: PropTypes.any,
    currencyElement: PropTypes.any,
    signElement: PropTypes.any,
}

const Symbol = ({ currency }) => {
    const epConfig = React.useContext(ConfigContext)
    if (!epConfig) {
        throw new Error('Config provider has not been loaded')
    }
    return getCurrencySymbol(currency, epConfig)
}

Symbol.propTypes = {
    currency: PropTypes.string,
}

class Currency extends React.PureComponent {
    static propTypes = {
        qty: PropTypes.shape({
            amount: PropTypes.number.isRequired,
            currency: PropTypes.string.isRequired,
        }),
        amountWrapper: PropTypes.elementType,
        currencyWrapper: PropTypes.elementType,
        onlyNumber: PropTypes.bool,
        formatOptions: PropTypes.object,
    }

    static defaultProps = {
        amountWrapper: null,
        currencyWrapper: null,
        onlyNumber: false,
        formatOptions: {},
    }

    renderAmount () {
        const {amountWrapper: Wrapper, qty, formatOptions} = this.props
        const qtyAbsolute = {
            ...qty,
            amount: Math.abs(qty.amount),
        }
        if (!Wrapper) {
            return <CurrencyNumber key='amount' qty={qtyAbsolute} formatOptions={formatOptions} />
        }
        return <Wrapper key='amount'>
            <CurrencyNumber qty={qtyAbsolute} formatOptions={formatOptions} />
        </Wrapper>
    }

    renderSign () {
        return (this.props.qty.amount < 0) ? '-' : ''
    }

    renderCurrency () {
        const {currencyWrapper: Wrapper, qty, onlyNumber} = this.props
        if (onlyNumber) {
            return null
        }

        if (!Wrapper) {
            return <Symbol key='symbol' currency={qty.currency} />
        }
        return <Wrapper key='symbol'>
            <Symbol currency={qty.currency} />
        </Wrapper>
    }

    render () {
        return <CurrencyWrapper key='currency'>
            <FormattedCurrency
                currency={this.props.qty.currency}
                amountElement={this.renderAmount()}
                currencyElement={this.renderCurrency()}
                signElement={this.renderSign()} />
        </CurrencyWrapper>
    }
}

export default Currency
