import React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'

export default ({ App, element = document.getElementById('root'), props = {} }) => {
    render(
        <AppContainer>
            <App {...props} />
        </AppContainer>,
        element
    )
}
