import moment from 'moment-timezone'

import {
  TIMESTAMP_FORMAT,
  DATE_FORMAT,
  DATETIME_FORMAT,
  LOCALE_TIME_FORMAT,
} from './constants'

function strictParseMoment(value, format = '') {
  return moment(value, format, true)
}

export function parseMoment(value, format = '') {
  const m = strictParseMoment(value, format)

  if (!m.isValid()) {
    throw new Error(`Invalid date \`${String(value)}\` (${format})`)
  }

  return m
}

export function maybeParseMoment(value, format = '', defaultValue) {
  const m = strictParseMoment(value, format)

  return m.isValid() ? m : defaultValue
}

export function parseTimestamp(value) {
  return parseMoment(value, TIMESTAMP_FORMAT)
}

export function maybeParseTimestamp(value, defaultValue) {
  const m = strictParseMoment(value, TIMESTAMP_FORMAT)

  return m.isValid() ? m : defaultValue
}

export function formatDay(day, format) {
  return day ? parseMoment(day).format(format) : ''
}

export function toDate(day) {
  return day ? parseMoment(day).toDate() : new Date()
}

export function toTimestamp(day) {
  return parseMoment(day).unix()
}

export function formatDate(day, format = DATE_FORMAT) {
  return moment(day).format(format)
}

export function formatDateTime(day) {
  return moment(day).format(DATETIME_FORMAT)
}

export function formatTimestamp(day) {
  return moment(day).format(TIMESTAMP_FORMAT)
}

export function formatISO(day) {
  return moment(day).toISOString()
}

export function formatMinuteDuration(minutes) {
  const duration = moment.duration(minutes, 'minutes')
  const durationComponents = [
    { value: duration.years(), unit: 'y' },
    { value: duration.months(), unit: 'M' },
    { value: duration.days(), unit: 'd' },
    { value: duration.hours(), unit: 'h' },
    { value: duration.minutes(), unit: 'm' },
    { value: duration.seconds(), unit: 's' },
  ]

  return durationComponents
    .filter(({ value }) => value !== 0)
    .slice(0, 3)
    .map(({ unit, value }) =>
      moment
        .localeData()
        .relativeTime(
          value,
          true,
          value > 1 ? `${unit}${unit}` : `${unit}`,
          false
        )
    )
    .join(', ')
}

export function makeHourTimeSpan(hours) {
  return moment.duration(hours, 'hours').asMilliseconds()
}

export function makeMinuteTimeSpan(minutes) {
  return moment.duration(minutes, 'minutes').asMilliseconds()
}

export function mergeDate(date, partialDate) {
  return toDate(parseMoment(date).set(partialDate))
}

export function hasMeridiem() {
  return moment().format(LOCALE_TIME_FORMAT).toLowerCase().indexOf('m') > -1
}

export function toParts(day) {
  const m = parseMoment(day)
  return [m.year(), m.month(), m.date(), m.hours(), m.minutes(), m.seconds()]
}

export function toMillis(seconds) {
  return seconds * 1000
}

export function getBrowserTimezone() {
  return moment.tz.guess()
}
