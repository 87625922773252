import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { display, height, space, width, minWidth, maxWidth, alignSelf } from '../../util/styles'
import useConfig from '../../hooks/useConfig'

const ImageComponent = styled.img`
  ${alignSelf}
  ${minWidth}
  ${maxWidth}
  ${width}
  ${height}
  ${display}
  ${space}
`

export function getLocalizedSrc (src, country, flavor = null, excludedFlavors = []) {
  const basePath = flavor && !excludedFlavors.some((excludedFlavor) => excludedFlavor === flavor) ? `/images/${flavor}/` : '/images/'
  return country ? src.replace('/images/', `${basePath}${country.toLowerCase()}/`) : src
}

const Image = ({ located, alt, country, src, ...props }) => {
  const flavor = useConfig('flavor', false)
  const currentCountry = useConfig('country')
  const excludedFlavors = useConfig('excludedFlavors')
  return (
    <ImageComponent
      alt={alt}
      src={located ? getLocalizedSrc(src, country || currentCountry, flavor, excludedFlavors) : src}
      {...props}
    />
  )
}


Image.defaultProps = {
  located: true,
}

Image.propTypes = {
  located: PropTypes.bool.isRequired,
  country: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default Image
