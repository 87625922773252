import PropTypes from 'prop-types'
import styled from 'styled-components'
import styledProps from 'styled-props'
import { transparentize } from 'polished'
import { type } from './styles'

import {
    borderWidth,
    borderStyle,
    borderRadius,
    borderColor,
    fontSize,
    padding,
    color,
} from '../../util/styles'
import { themeColor, themeFontFamily } from '../../util/themeLenses'

const backgroundColor = (props) => transparentize(0.9, themeColor(styledProps(type, 'type')(props))(props))
const fontFamily = themeFontFamily()
const Alert = styled.div`
    background-color: ${backgroundColor};
    font-family: '${fontFamily}';
    ${borderRadius};
    ${borderStyle};
    ${borderWidth};
    ${borderColor};
    ${fontSize};
    ${padding};
    ${color};
`

Alert.propTypes = {
    type: PropTypes.string,
    borderRadius: PropTypes.string,
    borderStyle: PropTypes.string,
    borderWidth: PropTypes.string,
    borderColor: PropTypes.string,
    padding: PropTypes.string,
    fontSize: PropTypes.string,
    color: PropTypes.string,
}

Alert.defaultProps = {
    type: 'warning',
    borderRadius: '4px',
    padding: '1em',
}

export default Alert
