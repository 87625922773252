import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CheckboxInputContainer, CheckboxInput, CheckButton, CheckboxLabel } from './layout'

class Check extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        label: PropTypes.any,
        errorColor: PropTypes.string,
        valid: PropTypes.bool,
        changed: PropTypes.bool,
        fontSize: PropTypes.string,
        fontColor: PropTypes.string,
        fontWeight: PropTypes.string,
        type: PropTypes.string.isRequired,
        'data-test': PropTypes.string,
        checkButtonElement: PropTypes.elementType,
        onFocus: PropTypes.func,
        forceFocus: PropTypes.bool,
        disabled: PropTypes.bool
    }

    static defaultProps = {
        label: '',
        valid: true,
        changed: false,
        fontSize: 'small',
        fontWeight: 'regular',
        fontColor: 'gray',
        'data-test': '',
        checkButtonElement: CheckButton,
        onFocus: () => {},
        forceFocus: false,
        disabled: false,
    }

    focus () {
        this.inputRef && this.inputRef.scrollIntoView()
    }

    focus = this.focus.bind(this)

    componentDidUpdate ({ forceFocus }) {
        if (!forceFocus && this.props.forceFocus) {
            this.focus()
            this.props.onFocus()
        }
    }

    componentDidMount () {
        const { forceFocus, onFocus } = this.props
        if (forceFocus) {
            this.focus()
            onFocus()
        }
    }

    render () {
        const { id, name, changed, valid, errorColor, label, fontSize, fontColor, fontWeight, type, checkButtonElement: CheckButtonElement, ...restProps } = this.props
        const dataTest = this.props['data-test']
        return (
            <CheckboxInputContainer changed={changed} valid={valid} errorColor={errorColor} dataTest={dataTest}>
                <CheckboxInput type={type} id={id} name={name} {...restProps} />
                <CheckboxLabel ref={(ref) => (this.inputRef = ref)} fontSize={fontSize} fontColor={fontColor} fontWeight={fontWeight} htmlFor={id} {...restProps}>
                    <CheckButtonElement fontSize={fontSize} {...restProps}/>
                    {label}
                </CheckboxLabel>
            </CheckboxInputContainer>
        )
    }
}

export { CheckButton }

export default Check
