import styled from 'styled-components'

import {
  space,
  color,
  flex,
  order,
  alignSelf,
  display,
  position,
  minHeight,
  maxWidth,
  style,
  backgroundSize,
  backgroundPosition,
  backgroundRepeat,
  zIndex,
} from 'styled-system'

const width = style({
  prop: 'width',
  key: 'width',
  alias: 'w',
  cssProperty: 'width',
})

const height = style({
  prop: 'height',
  alias: 'h',
  cssProperty: 'height',
})

const background = style({
  prop: 'background',
  key: 'colors',
  cssProperty: 'background',
})

const backgroundImage = style({
  prop: 'backgroundImage',
  alias: 'bgImage',
  cssProperty: 'backgroundImage',
  transformValue: (value) => `url('${value}')`,
})

const fontSize = style({
  prop: 'fontSize',
  alias: 'size',
  key: 'font.sizes',
  cssProperty: 'fontSize',
})

const textAlign = style({
  prop: 'textAlign',
  alias: 'align',
  cssProperty: 'textAlign',
})

const Box = styled.div`
  box-sizing: border-box;
  ${background}
  ${width}
  ${height}
  ${space}
  ${fontSize}
  ${color}
  ${flex}
  ${order}
  ${alignSelf}
  ${maxWidth}
  ${minHeight}
  ${textAlign}
  ${display}
  ${position}
  ${backgroundImage}
  ${fontSize}
  ${backgroundSize}
  ${backgroundPosition}
  ${backgroundRepeat}
  ${zIndex}
`

Box.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...fontSize.propTypes,
}

export default Box
