import React from 'react'
import PropTypes from 'prop-types'

export const DEFAUL_TIMEOUT = 5

class Timer extends React.Component {
  static propTypes = {
    autoPlay: PropTypes.bool,
    maxCounter: PropTypes.number,
    render: PropTypes.func,
    seconds: PropTypes.number,
  }

  static defaultProps = {
    autoPlay: true,
    seconds: DEFAUL_TIMEOUT,
  }

  state = {
    counter: 0,
  }

  interval = null

  componentDidMount() {
    if (this.props.autoPlay) {
      this.start()
    }
  }

  componentWillUnmount() {
    this.stop()
  }

  stop() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  stop = this.stop.bind(this)

  start() {
    if (this.props.seconds > 0) {
      this.interval = setInterval(
        this.incrementCounter,
        this.props.seconds * 1000
      )
    }
  }

  start = this.start.bind(this)

  incrementCounter() {
    this.stop()
    this.setState(
      ({ counter }) => ({
        counter: counter < this.props.maxCounter - 1 ? counter + 1 : 0,
      }),
      () => {
        this.start()
      }
    )
  }

  incrementCounter = this.incrementCounter.bind(this)

  decrementCounter() {
    this.stop()
    this.setState(
      ({ counter }) => ({
        counter: counter > 0 ? counter - 1 : this.props.maxCounter - 1,
      }),
      () => {
        this.start()
      }
    )
  }

  decrementCounter = this.decrementCounter.bind(this)

  render() {
    return this.props.render({
      decrementCounter: this.decrementCounter,
      incrementCounter: this.incrementCounter,
      counter: this.state.counter,
    })
  }
}

export default Timer
